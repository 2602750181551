import { render, staticRenderFns } from "./InviteUserPage.vue?vue&type=template&id=1e23468b&class=invite-user-wrapper"
import script from "./InviteUserPage.vue?vue&type=script&lang=js"
export * from "./InviteUserPage.vue?vue&type=script&lang=js"
import style0 from "./InviteUserPage.vue?vue&type=style&index=0&id=1e23468b&prod&scope=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports