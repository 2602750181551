import request from '@/utils/request'
const ETOUSOFT_URL = process.env.VUE_APP_ETOUSOFT_API_URL
const API = '/api/sendinvitation'

export function SendInvitation(params = {}) {
  var url = API
  return request({
    url: ETOUSOFT_URL + url,
    method: 'POST',
    data: params
  })
}
