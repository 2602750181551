import request from '@/utils/axios'
const ETOUSOFT_URL = process.env.VUE_APP_BASE_URL
const API = '/user/get-other-info'

export function GetOtherInfo(id) {
  var url = API + '/' + id;
  return request({
    url: ETOUSOFT_URL + url,
    method: 'get',
  })
}
