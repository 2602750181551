<template>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        max-width="1000"
        @input="checkDialogClose"
      >
        <v-card class="pa-3">
            <v-card-title class="text-h5 grey text--white lighten-4 d-flex justify-lg-space-between elevation-1" style="font-weight: 600;">
                <div>
                    Proof of Payments Attachments
                </div>
                <v-btn icon @click="handleCloseDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="py-2 px-0">
                <v-data-table
                :headers="headers"
                :items="attachments"
                :items-per-page="5"
                hide-default-footer
                v-model="selected"
                class="elevation-1"
                >
                    <template v-slot:item.file="{ item, index }">
                        <template v-if="checkFileType(item.attachment.file_type)">
                            <div class="py-3" style="cursor: pointer;" @click="openViewer">
                                <v-img
                                :lazy-src="item.attachment.path"
                                :src="item.attachment.path"
                                max-height="75"
                                max-width="75"
                                ></v-img>
                            </div>
                        </template>
                        <template v-else>
                            <div class="py-3">
                                <span class="file-icon"><v-icon size="75">mdi-file</v-icon></span>
                            </div>
                        </template>
                    </template>

                    <template v-slot:item.payment_status="{ item }">
                        {{ handleGetPaymentStatus(item.payment_status) }}
                    </template>
                    <template v-slot:item.created_at="{ item }">
                        {{ formattedDate(item.created_at) }}
                    </template>
                    <template v-slot:item.action="{ item }">
                      <v-btn icon class="action-btn" @click="handleDownloadAttachment(item)">
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </template>

                </v-data-table>
            </v-card-text>
        </v-card>
      </v-dialog>
      <!-- <v-dialog
        v-model="show_images"
        max-width="1000"
        @input="checkDialogCloseImages"
      >
        <viewer :images="images">
            <img v-for="(item,index) in images" :key="index" :src="item.attachment.path">
        </viewer>
      </v-dialog> -->
      <!-- <viewer :images="images" :options="viewerOptions">
            <img v-for="(item,index) in images" :key="index" :src="item.attachment.path">
    </viewer> -->
    </v-row>
  </template>

<script>
import { changeFormat } from '@/utils/dateHelper';
import { payment_status } from  '@/helpers/payment_status';
export default {
    name:'DuplicateSentRequest',
    props: {
        open_modal: {
             type: Boolean,
        },
        items: {},
        attachments: {},
        company_id: {},
        billing_id: {},
        request_id: {},
    },
    data () {
        return {
             images: [],
             dialog: false,
             show_images: false,
             datas:[],
             user:this.$store.getters.user.user,
             selected:[],
             headers: [
                    { text: 'File', value: 'file' },
                    { text: 'File Name', value: 'attachment.file_name' },
                    // { text: 'File Size', value: 'attachment.file_size' },
                    { text: 'File Type', value: 'attachment.file_type' },
                    { text: 'Date Upload', value: 'created_at' },
                    { text: 'Action', value: 'action' },
                ],
        }
    },
    methods:{
        openViewer() {
            const item_images = this.images.map((item)=>{
                return item.attachment.path;
            })
            this.$viewerApi({
                images: item_images,
                toolbar: {
                    zoomIn: 1,
                    zoomOut: 1,
                    oneToOne: 1,
                    reset: 1,
                    play: 1,
                    fullscreen: 1,
                    rotation: 1,
                    zoom: 1,
                    crop: 1,
                    toggle: 1,
                    // Custom button configuration
                    custom: [
                        {
                            title: 'Download',
                            className: 'custom-download-btn',
                            click: () => {console.log('test')}
                        }
                    ]
                }
            })
        },
        checkDialogClose(val) {
           if(!val){
               this.$emit('handleModalHide')
           }
       },
        checkFileType(file_type) {
           const type = file_type.split('/')[0]
           if(type == 'image'){
            return true
           }
           return false;
       },
       checkDialogCloseImages(val) {
           if(!val){
               this.show_images = false
           }
       },
       handleGetPaymentStatus(id) {
         const status_payment = payment_status.find(item=>item.value == id);
         return status_payment.text
        },
       handleCloseDialog() {
            this.$emit('handleModalHide')
       },
       handleSumbit() {
            this.$emit('handleAddRelatedRequest',this.selected)
       },
       handleMarkAsPaid() {
            this.$emit('markAsPaid')
       },
       formattedDate(date) {
          return changeFormat(date); // Example format string
       },
       handleSendRequest(val) {
          this.$emit('sendBillRequest', val)
       },
       handleRemoveFromList(index) {
          this.datas.splice(index,1)
       },
       getRelatedRequest(index) {
            this.$notiflix.Loading.arrows();
             this.axios.get(`/request/related/${this.company_id}/${this.billing_id}?request_id=${this.request_id}`).then((res)=>{
                this.exclude_ids = res.data.data.data.filter(item => {
                    return item.request_sent && item.payment_status == 1 ;
                });
                this.has_to_send = res.data.data.data.filter(item => {
                    return item.payment_status == 1 ;
                });
                this.company_name = res.data.company
                this.billing_email = res.data.email
                this.total_amount = res.data.sum
                this.datas = res.data.data.data
                this.total_page = res.data.data.total
             }).catch((err)=>{
                  console.log(err,'err')
             }).finally(()=>{
              this.$notiflix.Loading.remove();
             })
       },
       handleDownloadAttachment(item){
            this.$notiflix.Loading.arrows();
            this.axios.post('/resources/download',{...item.attachment, user_id:this.user.id},{
				responseType: 'blob',
			}).then((response)=>{
                if(response.status == 200){
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', item.attachment.file_name); 
                    document.body.appendChild(link);
                    link.click();
			}
           }).catch((err)=>{
                console.log(err,'err')
           }).finally((err)=>{
                this.$notiflix.Loading.remove();
           })
        },
    },

    watch: {
        'open_modal'(newValue) {
            this.dialog = newValue;
            // if(newValue){
            //     this.getRelatedRequest();
            // }
        },
        'items'(newValue) {
           this.datas = newValue
        },
        'attachments'(newValue) {
            if(newValue.length){
                console.log(newValue,'newValue')
                this.images = newValue.filter(item => {
                    return this.checkFileType(item.attachment.file_type) ;
                });
            }
        },
    }
}
</script>

<style scoped>
.request-duplicate-confirmation{
    border-radius: 20px !important;
}

.files_required{
    border: solid 3px red;
}
.confirm-text{
    font-family: inherit !important;
    color: #000 !important;
    width: 100%;
    font-size: 15px;
    margin-bottom: 20px;
}
.file-icon * {
    color: blue;
}
</style>