<template>
  <v-row justify="center">
   <v-dialog
     v-model="dialog"
     persistent
     top
     max-width="600px"
     @input="checkDialogClose"
   >
   
     <v-card>
       <v-card-title class="text-h5 grey lighten-2 d-flex justify-lg-space-between">
         <div>
             Billing Details
         </div>
         <v-btn icon @click="handleCloseDialog">
           <v-icon>mdi-close</v-icon>
       </v-btn>
       </v-card-title>
    <v-card-text class="px-4 mt-4">
           <v-form
               ref="form"
               v-model="valid"
               lazy-validation
           >
           <v-text-field
               v-model="payload.company_name"
               label="Name"
               outlined
               required
               :rules="nameRules"
           ></v-text-field>
           <v-text-field
               label="Email Address"
               v-model="payload.email_address"
               outlined
               required
               :rules="emailAddressRules"
           ></v-text-field>
           </v-form>
       </v-card-text>
       <v-card-actions  class="grey lighten-2">
         <v-spacer></v-spacer>
         <v-btn
           depressed
           color="primary"
           @click="handleSumbit"
           :loading="loading"
           >
           {{payload.id ? 'Update' : 'Add'}}
           </v-btn>
       </v-card-actions>
     </v-card>
   </v-dialog>
 </v-row>
 </template>

<script>
export default {
   props: {
       open_modal: {
           type: Boolean,
       },
       selected_item:{}
   },
   data () {
       return {
           dialog: false,
           loading: false,
           valid: true,
           nameRules: [
               v => !!v || 'Company Name is required',
           ],
           emailAddressRules: [
               v => !!v || 'Email Address is required',
               v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
           ],
           

           payload : {
                company_name : '',
                email_address : '',
                user_id : this.$store.getters.user.user.id,
                company_id : this.$store.getters.user.default_user_company.company_id, 
           }
       }
   },
   methods:{
       handleSumbit() {
           if(this.$refs.form.validate()){
               this.loading = true
               this.axios.post('/billing',this.payload).then((res)=>{
                   if(res){
                       this.loading = false
                       this.$awn.success(`${this.payload.id ? 'Update' : 'Added'} Successfully`);
                       this.$emit('handleModalHide');
                       this.$emit('handleFetchBilling');
                       this.handleReset();
                   }
               }).catch((error)=>{
                   console.log(error,'error')
               }).finally((fin)=>{
                 this.loading = false
               })
           }
       },
       checkDialogClose(val) {
           if(!val){
               this.$emit('handleModalHide')
           }
       },
       handleCloseDialog() {
               this.$emit('handleModalHide')
       },
       handleValidation(type){
           return [
               v => !!v || `${type} is required`,
               v =>  /.+@.+\..+/.test(v) || 'E-mail must be valid',
           ];
       },
       handleReset(){
        this.payload = {
            company_name : '',
            email_address : '',
            user_id : this.$store.getters.user.user.id,
        }
        this.$refs.form.resetValidation()
       }
   },
   watch: {
       'open_modal'(newValue) {
           this.dialog = newValue;
           if(!newValue){
                this.handleReset();
           }
       },
       'selected_item'(newValue) {
            this.payload = {
                id : newValue.id,
                company_name : newValue.company_name,
                email_address : newValue.email_address,
                user_id : this.$store.getters.user.user.id,
            };
         }
   }
}
</script>