import { login, getInfo, getSettings, logout } from '@/api/login'
import { GetOtherInfo } from '@/api/local_other_info'
import { getToken, setToken, removeToken } from '@/utils/auth'
const user = {
  state: {
    token: getToken(),
    user : null,
    other_info : null,
    name: '',
    // avatar: '',
    // roles: [],
    // currentUser: null,
    // allowedViews: [],
    // defaultAvatar: null,
    base_url: null,
    // user_company: [],
    default_user_company: null,
    // affiliated_company: null,
    user_settings: null,
    headers: {
      Authorization: 'Bearer ' + getToken()
    }
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_USER: (state, data) => {
      state.user = data
    },

    SET_USER_OTHER_INFO: (state, data) => {
      state.other_info = data
    },
    // SET_AVATAR: (state, avatar) => {
    //   state.avatar = avatar
    //   state.defaultAvatar = avatar
    // },
    // SET_DEFAULT_AVATAR: (state, avatar) => {
    //   state.defaultAvatar = avatar
    // },
    SET_BASE_URL: (state, url) => {
      state.base_url = url
    },
    // SET_USER: (state, user) => {
    //   state.currentUser = user
    // },
    // SET_ROLES: (state, roles) => {
    //   state.roles = roles
    // },
    SET_HEADER: (state, token) => {
      state.headers.Authorization = 'Bearer ' + token
    },
    // SET_USER_COMPANY: (state, user_company) => {
    //   state.user_company = user_company
    // },
    SET_DEFAULT_USER_COMPANY: (state, default_user_company) => {
      state.default_user_company = default_user_company
    },
    // SET_AFFILIATED_COMPANY: (state, affiliated_company) => {
    //   state.affiliated_company = affiliated_company
    // },
    SET_USER_SETTINGS: (state, user_settings) => {
      state.user_settings = user_settings
    }
  },

  actions: {

    Login({ commit }, userInfo) {
      const email = userInfo.email.trim()
      return new Promise((resolve, reject) => {
        login(email, userInfo.password, userInfo.is_admin).then(response => {
          console.log(response,'response')
          var data = response.data
          setToken(data.token)
          commit('SET_TOKEN', data.token)
          commit('SET_HEADER', data.token)
          commit('SET_USER', data.user)
          resolve()
        }).catch(error => {
          console.log(error,'error')
          reject(error)
        })
      })
    },
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo(state.token).then(response => {
          var data = response.data.user
          commit('SET_USER', data)

          commit('SET_NAME', data.first_name + ' ' + data.last_name)
          // commit('SET_AVATAR', data.avatar_path)
          // commit('SET_DEFAULT_AVATAR', response.data.default_avatar)
          commit('SET_BASE_URL', response.data.base_url)
          // commit('SET_USER_COMPANY', data.user_company)
          commit('SET_DEFAULT_USER_COMPANY', data.default_user_company)
          // commit('SET_AFFILIATED_COMPANY', data.affiliated_company)
          // getSettings(data.default_user_company.id).then(res => {
          //   commit('SET_USER_SETTINGS', res.data)
          // }).catch(error => {
          //   console.log(error)
          // })
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    async GetUserOtherInfo({ commit, state }, token) {
      var data = await GetOtherInfo(state.user.id);
      if(data.data.success){
        commit('SET_USER_OTHER_INFO', data.data.other_info)
      }
 
    },
    RegisterToken({ commit }, token) {
      return new Promise((resolve, reject) => {
        setToken(token)
        commit('SET_TOKEN', token)
        commit('SET_HEADER', token)
        resolve()
      })
    },
    LogOut({ commit, state }) {
      logout(state.token)
      commit('SET_TOKEN', '')
      commit('SET_HEADER', '')
      removeToken()
    },
    FedLogOut({ commit, state }) {
      return new Promise(resolve => {
        logout(state.token)
        commit('SET_TOKEN', '')
        commit('SET_HEADER', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
