const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  token: state => state.user.token,
  name: state => state.user.name,
  user: state => state.user,
  headers: state => state.user.headers,
  base_url: state => state.user.base_url,
  user_settings: state => state.user.user_settings
}

export default getters

