import HomePage from '../components/pages/HomePage.vue';
import invite_user from '../components/pages/InviteUserPage.vue';
import manage_user from '../components/pages/ManageUserPage.vue';
import billing from '../components/pages/BillingPage.vue';
import billing_logs from '../components/pages/BillingLogs.vue';
import application from '../components/pages/ApplicationPage.vue';
import company_billing_details from '../components/pages/CompanyBillingDetails.vue';
import billing_settings from '../components/pages/BillingDetailsSetting.vue';
import company_settings from '../components/pages/CompanyDetailsSetting.vue';

export default [
    {
      path: `/home`,
      name: `home`,
      component: HomePage,
    },
    {
      path: `/invite-user`,
      name: `invite-user`,
      component: invite_user,
      meta: { requiresRole: true, role:['SA','CA'], is_admin:false }
    },
    {
      path: `/manage-user`,
      name: `manage-user`,
      meta: { requiresRole: true },
      component: manage_user,
    },
    {
      path: `/billing`,
      name: `billing`,
      component: billing,
    },
    {
      path: `/billing/logs`,
      name: `billing`,
      component: billing_logs,
      meta: { requiresRole: true, requireIsAdmin:true },
    },
    {
      path: `/billing/:company_id/:billing_id`,
      name: `billing`,
      component: company_billing_details,
    },
    {
      path: `/application`,
      name: `application`,
      component: application,
    },
    {
      path: `/application/view/:id`,
      name: `application_view`,
      component: application,
    },
    {
      path: `/billing-settings`,
      name: `billing-settings`,
      component: billing_settings,
      meta: { requiresRole: true, role:['SA','CA'], is_admin:false },
    },

    {
      path: `/company-settings`,
      name: `company-settings`,
      meta: { requiresRole: true, role:['SA','CA'], is_admin:false },
      component: company_settings,
    },

]