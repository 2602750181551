<template>
    <div class="admin-setting-wraper">
            <div class="d-flex py-5 align-center">
                <p class="ma-0 text-h5 mr-5"><strong>Settings</strong></p>
            </div>
          <v-card class="mt-0">
            <v-card-title class="d-flex justify-lg-space-between">
                <div class="text-h6">
                    <strong>Company</strong>
                </div>
                <v-btn rounded small color="secondary" @click="company_modal = true">
                    <v-icon size="12" class="mr-1">mdi-plus-circle</v-icon>New
                </v-btn>
            </v-card-title>
            <hr class="mx-2"/>
            <v-card-text>
                <div>
                    <v-data-table
                        :loading="loading_table"
                        :headers="headers"
                        :items="datas"
                        :page.sync="page"
                        :items-per-page="10"
                        hide-default-footer
                        class="elevation-1"
                        @page-count="pageCount = $event"
                    >
                    <template v-slot:item.id="{ item, index }">
                        {{ index + 1 }}
                    </template>
                    <template v-slot:item.created_at="{ item, index }">
                        {{ formattedDate(item.created_at) }}
                    </template>
                    <template v-slot:item.company="{ item, index }">
                        {{item.company.name}}
                    </template>
                    <template v-slot:item.number_requests="{ item, index }">
                       <v-chip color="primary" class="text-white"> {{item.request.length}}</v-chip>
                    </template>

    
                    <template v-slot:item.action="{ item }">
                        <v-btn icon @click="handleEditCompanyList($event,item)">
                            <v-icon size="20">mdi-pencil-circle</v-icon>
                        </v-btn>
                        |
                        <v-btn icon @click="handleDeleteCompanyList($event,item)">
                            <v-icon size="20" color="red">mdi-delete-circle</v-icon>
                        </v-btn>
                    </template>
                                                            
                    
                    </v-data-table>
                </div>
                <div class="text-center mt-5 elevation-3 grey lighten-2">
                    <v-pagination
                        v-model="page"
                        @input="handlePageChange"
                        :length="datas.last_page"
                    ></v-pagination>
                </div>
            </v-card-text>
          </v-card>
          <Company 
            :open_modal="company_modal" 
            :item_selected="item_company_selected" 
            @handleModalHide = "company_modal = false, item_company_selected=null"
            @handleCompanyAdded="company_modal = false"
        />
    </div>
  </template>
  
  <script>
  import { changeFormat } from '@/utils/dateHelper';
  import { payment_status } from  '@/helpers/payment_status';
  import { insurance_status } from  '@/helpers/insurance_status';
  import Company from  '../modals/Company.vue';
  export default {
    components:{
        Company
    },
    data () {
      return {
        company_modal : false,
        logs_selected : [],
        item_company_selected : [],
        selected : [],
        dateFrom: null,
        dateTo: null,
        menuFrom: false,
        menuTo: false,
        loading_table : false,
        user : this.$store.getters.user.user,
        search:null,
        showeducationmodal:false,
        showeligibilitymodal:false,
        payment_status:payment_status,
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        headers: [
          { text: 'Company', value: 'name' },
          { text: 'Number of requests', value: 'number_requests' },
          { text: 'Created at', value: 'created_at' },
          { text: 'Action', value: 'action' },

        ],
        datas:[],                             
        companies:[],
        query:{
          company : null,
          payment_status : null,
        },
      }
    },
  
    mounted(){
      this.getCompany();
    },
  
    methods:{
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },

        formattedDate(date) {
          return changeFormat(date); // Example format string
        },

        handleEditCompanyList(event, item){
          this.item_company_selected = item
          this.company_modal = true
        },


        handleDeleteCompanyList(event, item){
            event.stopPropagation();
            if(item.request.length){
                return this.$notiflix.Report.failure(
                    'Warning',
                    '<h5>You can’t delete this billing because it is currently in use.</h5>',
                    'Okay',
                );
            }
            else{
                this.$notiflix.Confirm.show(
                    'Delete',
                    'Are you sure, you want to delete this billing?',
                    'Yes',
                    'No',
                    () => {
                        this.deleteCompany(item)
                    },
                );
            }
        },

        deleteCompany(item){
            this.$notiflix.Loading.arrows();
            this.submitLoading = true;
            this.axios.delete(`/company/${item.id}`).then((res)=>{
                this.$awn.success(`Deleted Successfully`);
                this.getCompany();
            }).catch((error)=>{
                console.log(error,'error')
            }).finally((fin)=>{
                this.submitLoading = false;
            })
        },

        handleGetPaymentStatus(id) {
         const status_payment = payment_status.find(item=>item.value == id);
         return status_payment.text
        },
        handlePageChange() {
        //   this.getBillingLogs()
        },
        handleShowLogs(item) {
          this.logs_selected = item;
          this.mail_logs_modal = true;
        },
        handleGetInsuranceStatus(id) {
         const status = insurance_status.find(item=>item.value == id);
         return status.text
        },
        goTo(id){
            this.$router.push(`/application/${id}`)
        },
        goToSumarry(item){
            this.$router.push(`/billing/${item.user.client_company.company_id}/${item.billing_id}`)
        },
        getCompany(){
            this.$notiflix.Loading.arrows();
            this.axios.get(`/company/get-company`).then((res)=>{
                this.datas = res.data.data
            }).catch((err)=>{
                console.log(err,'err')
            }).finally(()=>{
                this.$notiflix.Loading.remove();
            })
        },
    },
    // watch: {
    //   dateFromPicker (val) {
    //     this.dateFrom = this.formatDate(this.dateFromPicker)
    //   },
    // },

  }
  </script>
  
  <style lang="scss" scoped>
    .admin-setting-wraper{
        background-color: #ebebeb;;
        height: 100%;
        padding: 0 15px;

        .v-card{
            border-radius: 5px;
        }
    }
  </style>