export const descriptions = [
    { value: 1, text: 'NOA' },
    { value: 3, text: 'PROOF OF LBC' },
    { value: 6, text: 'POLICY CONTRACT'},
    { value: 4, text: 'INVOICE' },
    { value: 2, text: 'PROOF OF DELIVERY' },
    { value: 5, text: 'PROOF OF PAYMENT'},
    { value: 7, text: 'OTHERS'},
]

export default descriptions;