<template>
  <v-app id="inspire">

    <v-navigation-drawer v-model="drawer" app id="drawer-wrapper">
      <div class="pa-4 text-center" id="drawer-wrapper">
        <v-img
          max-height="150"
          max-width="250"
          src="/logo.png"
        ></v-img>
      </div>
      <hr/>

      <div class="drawer-wrapper">
        <v-col id="left-list" class="pa-0">
            <v-list class="pt-0">

                <v-list-item-group v-model="model">
                    <template v-for="(item, i) in links">
                      <template v-if="handleCheckRole(item)">
                        <v-list-item :class="`sidebar-links ${handleCheckActiveRoute(item) ? `v-item--active` : ''}`" :key="i" @click="goTO(item.route)" v-if="!item.submenus" >
                          <v-list-item-icon class="mr-4 pa-0">
                              <v-icon >{{ item.icon }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content  class="mr-4 pa-0">
                              <v-list-item-title v-text="item.text"  class="text-white"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                
                        <v-list-group v-else class="pa-0 menu-icons sidebar-links" :key="item.id" >
                          <template v-slot:activator>
                            <v-list-item-icon class="mr-4 pa-0">
                                <v-icon >{{item.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title class="text-white">{{item.text}}</v-list-item-title>
                            </v-list-item-content>
                          </template>
                          <v-list-item  v-for="(menu, i) of item.submenus" :key="i" link @click="goTO(menu.route)" id="submenus" active-class="submenus-active">
                            <v-list-item-icon class="mr-4 pa-0">
                              <v-icon v-if="menu.icon">{{ menu.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content  class="mr-4 pa-0">
                              <v-list-item-title v-text="menu.text"  class="text-white"></v-list-item-title>
                          </v-list-item-content>
                          </v-list-item>
                        </v-list-group>
                      </template>
                  </template>
                  
                </v-list-item-group>

            </v-list>
        </v-col>
      </div>  
    </v-navigation-drawer>


    <v-app-bar app>
      <div class="d-flex justify-lg-space-between align-center" style="width: 100%;">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <!-- <v-toolbar-title>Application</v-toolbar-title> -->
        <user-info/>
        <!-- <v-toolbar-title>Application</v-toolbar-title> -->
      </div>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
  import UserInfo from  '@/components/UserInfo.vue';

   export default {
    components:{
        UserInfo,
    },
    data: () => ({
      cards: ['Today', 'Yesterday'],
      drawer: null,
      model: null,
      links: [
        {
          text: "Home",
          route: "home",
          icon : 'mdi-home',
        },
        {
          text: "Invite User",
          route: "invite-user",
          icon :"mdi-account-multiple-plus",
          requiresRole:true,
          role:['SA','CA'],
          is_admin : false,
        },
        {
          text: "Manage User",
          route: "manage-user",
          requiresRole:true,
          role:['SA','CA'],
          icon :"mdi-account-settings",
        },
        {
          text: "Billing",
          route: "billing",
          icon :"mdi-account-multiple-plus",
        },
        {
          text: "Admin Setting",
          route: "admin-settings",
          icon :"mdi-cog",
          requiresRole:true,
          role:['SA','CA'],
          is_admin : false,
          submenus : [
            {
              text: "Billing",
              route: "billing-settings",
            },
            {
              text: "Company",
              route: "company-settings",
            }
          ]
        },

      ],
    }),
    methods:{
      handleCheckRole(item){
        const user = this.$store.getters.user.user;
        if(item.requiresRole){
          if(item.role && item.role.indexOf(user.default_user_company.role) !== -1){
            if(item.is_admin == user.is_admin){
              return true;
            }
            else if(item.is_admin === undefined){
              return true;
            }
            else{
              return false
            }
          }
          else{
            return false;
          }
        }
        else{
          return true;
        }

      },
      goTO(route){
        this.$router.push({name:route})
      },
      handleCheckActiveRoute(item){
       if(this.$route.name == item.route){
        return true
       }
       else{
        return false
       }
      }
    }
  }
</script>