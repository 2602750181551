<template>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        max-width="560"
        @input="checkDialogClose"
      >
        <v-card class="request-duplicate-confirmation">
          <v-card-title class="text-h5 justify-lg-center" style="font-weight: 600">
            <div class="flex-grow-1 text-center">
                Send Bill
            </div>
            <div class="ml-auto">
                <v-btn icon @click="handleCloseModal">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
          </v-card-title>
          <hr class="mx-2 mb-5"/>

          <v-card-text>
            <p class="confirm-text">You have already sent this unpaid requests lists. Are you sure you want to send it again?</p>
            <div>
              <p class="confirm-text mb-1" style="font-size: 18px;"><strong>Total : {{formatNumber(this.total)}}</strong></p>
            </div>
            <table>
                <thead>
                    <th>ID</th>
                    <th>DATE</th>
                    <th>REQUESTOR</th>
                    <th>PROJECT NAME</th>
                    <th>AMOUNT</th>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in items" :key="index">
                        <td>
                            {{index + 1}}
                        </td>
                        <td>
                           {{ formattedDate(item.created_at) }}
                        </td>
                        <td>
                            {{item.user.full_name}}
                        </td>
                        <td>
                              {{item.project_name}}
                        </td>
                        <td>
                          {{formatNumber(item.total_amount)}}
                        </td>
                    </tr>
                </tbody>
            </table>
          </v-card-text>
          <v-card-actions class="d-flex justify-lg-space-between mt-2" style="gap:20px">
            <v-btn
              color="primary"
                class="flex-grow-1 py-5"
              rounded
              @click="handleSendRequest(true)"
            >
              Yes
            </v-btn>
            <v-btn
              color="white"
              class="flex-grow-1 py-5"
              rounded
              @click="handleSendRequest(false)"
            >
              No
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>

<script>
import { changeFormat } from '@/utils/dateHelper';

export default {
    name:'DuplicateSentRequest',
    props: {
        open_modal: {
             type: Boolean,
        },
        items: {}
    },
    data () {
        return {
             dialog: false,
             total: 0,
        }
    },
    methods:{
      formatNumber(value) {
        // Format the number with commas
            if (typeof value !== 'string') {
                value = String(value || ''); // Ensure value is a string
            }
            const [integerPart, decimalPart] = value.split('.');
            const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;

      },
      checkDialogClose(val) {
           if(!val){
               this.$emit('handleModalHide')
           }
       },
       handleCloseModal() {
        this.$emit('handleModalHide')
       },
       formattedDate(date) {
          return changeFormat(date); // Example format string
       },
       handleSendRequest(val) {
          this.$emit('sendBillRequest', val)
       },
    },

    watch: {
        'open_modal'(newValue) {
            this.dialog = newValue;
        },
        'items'(newValue) {
          newValue.forEach((value) => {
           this.total = this.total + value.total_amount
          });
        },
    }
}
</script>

<style scoped>
.request-duplicate-confirmation{
    border-radius: 20px !important;
}
table {
        width: 100%;
        border-collapse: collapse;
}
table * {
    font-size: 13px !important;
    color: #000;
}
td, th {
    border: 1px solid #ddd;
    padding: 8px;
    width: fit-content;
}
th {
    background-color: #f6f6f6;
    color: #000;
}
.files_required{
    border: solid 3px red;
}
.confirm-text{
    font-family: inherit !important;
    color: #000 !important;
    width: 100%;
    font-size: 15px;
    margin-bottom: 20px;
}
</style>