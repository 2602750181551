<template>
   <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      top
      max-width="600px"
      @input="checkDialogClose"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2 d-flex justify-lg-space-between">
          <div>
              Procuring Entity
          </div>
          <v-btn icon @click="handleCloseDialog">
            <v-icon>mdi-close</v-icon>
        </v-btn>
        </v-card-title>
        <v-card-text class="px-4 mt-4">
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
            <v-text-field
                v-model="payload.name"
                label="Name"
                outlined
                required
                :rules="handleValidation('Name')"
            ></v-text-field>
            <v-text-field
                label="Address"
                v-model="payload.address"
                outlined
                required
                 :rules="handleValidation('Address')"
            ></v-text-field>
            </v-form>
        </v-card-text>
        <v-card-actions  class="grey lighten-2">
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="primary"
            @click="handleSumbit"
            >
            {{ payload.id }}
            {{payload.id ? 'Update' : 'Add'}}
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
  </template>

<script>
export default {
    props: {
        open_modal: {
            type: Boolean,
        },
        edit_procuring: {
            type: Object,
        },
    },
    data () {
        return {
            dialog: false,
            valid: true,
            nameRules: [
                v => !!v || 'Name is required',
            ],

            payload : {
                name : '',
                address : '',
            }
        }
    },
    methods:{
        handleSumbit() {
            if(this.$refs.form.validate()){
                this.axios.post('/procuring',this.payload).then((res)=>{
                    this.$awn.success(`${this.payload.id ? 'Updated Successfully' : 'Added Successfully'}`);
                    if(res){
                        this.$emit('handleModalHide');
                        this.$emit('handleFetchProcuring');
                    }
                }).catch((error)=>{
                    console.log(error,'error')
                })
            }
        },
        checkDialogClose(val) {
            if(!val){
                this.$emit('handleModalHide')
            }
        },
        handleCloseDialog() {
                this.$emit('handleModalHide')
        },
        handleValidation(type){
            return [
                v => !!v || `${type} is required`,
            ];
        }
    },
    watch: {
        'open_modal'(newValue) {
            this.dialog = newValue;
        },

        'edit_procuring'(newValue) {
            if(newValue){
                this.payload = newValue;
            }
            else{
                this.payload = {
                    name : '',
                    address : '',
                };
            }
        },
    }
}
</script>