<template>
  <div class="login-container">
    <div class="login-form">
      <div class="pa-4 text-center" id="drawer-wrapper">
            <v-img
            max-width="270"
            src="/logo.png"
            ></v-img>
      </div>
      <v-btn color="secondary" large @click="handleLogin" :loading="loading">Sign in</v-btn>
    </div>
  </div>
</template>

<script>


export default {
  name: 'LoginPage',
  data() {
    return {
      par: {},
      loading: false
    }
  },
  created() {
    this.par = this.$route.query
  },
  methods: {
    handleLogin() {
      this.loading = true;
      var url = (Object.keys(this.par).includes('redirect')) ? '?site_redirect=' + this.par.redirect : ''
      var fullurl = (url !== '') ? url + '&product=3' : '?product=3'
      window.location.href = process.env.VUE_APP_ETOUSOFT_FRONTEND_URL + 'login' + fullurl
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
  .login-container{
    display: flex;
  }
  .login-form{
    box-shadow:- rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 30%;
    margin: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    gap: 20px;
    border-radius: 10px;
  }
  .login-form h1{
   font-weight: 800;
  }
  .login-form button{
    width: 100%;
    background: blue !important;
  }
</style>
