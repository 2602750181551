import request from '@/utils/request'
const ETOUSOFT_URL = process.env.VUE_APP_ETOUSOFT_API_URL
// const ETOUSOFT_URL = process.env.ETOUSOFT_API_URL   <--- set this for production or staging...

export function login(email, password, is_admin) {
  return request({
    url: ETOUSOFT_URL + '/api/authenticate/adminlogin',
    method: 'post',
    data: {
      email,
      password,
      is_admin
    }
  })
}

export function getInfo(token) {
  return request({
    url: ETOUSOFT_URL + '/api/users/me',
    method: 'get',
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })
}

export function logout(token) {
  return request({
    url: ETOUSOFT_URL + '/api/logout',
    method: 'post',
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })
}
