<template>
    <v-row justify="center">
     <v-dialog
       v-model="dialog"
       persistent
       top
       max-width="1000px"
       @input="checkDialogClose"
     >
     
       <v-card>
         <v-card-title class="text-h5 grey lighten-2 d-flex justify-lg-space-between">
           <div>
            <v-btn color="primary" @click="handleSendBill">
                Resend
                <v-icon size="18" class="ml-2">mdi-send</v-icon>
            </v-btn>
           </div>
           <v-btn icon @click="handleCloseDialog">
             <v-icon>mdi-close</v-icon>
         </v-btn>
         </v-card-title>
         <v-card-text class="pb-0 mt-4">
            <div v-html="logs_selected.content"></div>
         </v-card-text>
         <v-card-actions></v-card-actions>
       </v-card>
     </v-dialog>
   </v-row>
   </template>
  
  <script>
  export default {
     props: {
         open_modal: {
             type: Boolean,
         },
         logs_selected: {}
     },
     data () {
         return {
             dialog: false,
             data: null,
             valid: true,
             nameRules: [
                 v => !!v || 'Branch Name is required',
             ],
             payload : {
                  branch_name : '',
                  company_id : null,
                  user_id : this.$store.getters.user.user.id,
             }
         }
     },
     methods:{
        handleSendBill() {
          this.$notiflix.Confirm.show(
                    'RESEND BILL',
                    'Are you sure you want resend this bill?',
                    'Yes',
                    'No',
                    () => {
                       this.sendBill();
                    },
                );
        },
        sendBill() {
            this.loading = true;
            var params ={
              company_id :this.logs_selected.company_id,
              billing_id :this.logs_selected.billing_id,
            }
            this.$awn.asyncBlock(
                this.axios.post('/billings/send-bill',params),
                null
            ).then(resp =>
                this.$emit('handeSendSuccess'),
            ).finally(
                resp =>  this.loading = false,
            );
        },
         checkDialogClose(val) {
             if(!val){
                 this.$emit('handleModalHide')
             }
         },
         handleCloseDialog() {
                 this.$emit('handleModalHide')
         },
         handleValidation(type){
             return [
                 v => !!v || `${type} is required`,
                 v =>  /.+@.+\..+/.test(v) || 'E-mail must be valid',
             ];
         },
         handleReset(){
          this.payload = {
              branch_name : '',
              user_id : this.$store.getters.user.user.id,
          }
         }
     },
     watch: {
         'open_modal'(newValue) {
             this.dialog = newValue;
         },
         'logs_selected'(newValue) {
              this.data = newValue;
          }
     }
  }
  </script>

  <style lang="scss" scoped>
  .v-card__text *{
    color: #000 !important;
    div #wrapper .table *{
        font-size: 14px !important;
    }

  }
  </style>