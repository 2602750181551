<template>
    <v-row justify="center">
     <v-dialog
       v-model="dialog"
       persistent
       top
       max-width="600px"
       @input="checkDialogClose"
     >
     
       <v-card>
         <v-card-title class="text-h5 grey lighten-2 d-flex justify-lg-space-between">
           <div>
              Company
           </div>
           <v-btn icon @click="handleCloseDialog">
             <v-icon>mdi-close</v-icon>
         </v-btn>
         </v-card-title>
         <v-card-text class="px-4 pb-0 mt-4">
             <v-form
                 ref="form"
                 v-model="valid"
                 lazy-validation
             >
                <v-text-field
                    v-model="payload.name"
                    label="Name"
                    outlined
                    required
                    :rules="nameRules"
                ></v-text-field>
             </v-form>
         </v-card-text>
         <v-card-actions  class="grey lighten-2">
           <v-spacer></v-spacer>
           <v-btn
             depressed
             color="primary"
             @click="handleSumbit"
             >
             {{payload.id ? 'Update' : 'Add'}}
             </v-btn>
         </v-card-actions>
       </v-card>
     </v-dialog>
   </v-row>
   </template>
  
  <script>
  export default {
     props: {
         open_modal: {
             type: Boolean,
         },
         item_selected:{}
     },
     data () {
         return {
             dialog: false,
             valid: true,
             nameRules: [
                 v => !!v || 'Company Name is required',
             ],
             payload : {
                  name : '',
                  user_id : this.$store.getters.user.user.id,
                  company_id : this.$store.getters.user.default_user_company.company_id, 
             }
         }
     },
     methods:{
         handleSumbit() {
             if(this.$refs.form.validate()){
                 this.axios.post('/company/save-bonds-company',this.payload).then((res)=>{
                    this.$awn.success('Added Successfully');
                     if(res){
                         this.$emit('handleCompanyAdded');
                         this.handleReset();
                     }
                 }).catch((error)=>{
                     console.log(error,'error')
                 })
             }
         },
         checkDialogClose(val) {
             if(!val){
                 this.$emit('handleModalHide')
             }
         },
         handleCloseDialog() {
                 this.$emit('handleModalHide')
         },
         handleValidation(type){
             return [
                 v => !!v || `${type} is required`,
                 v =>  /.+@.+\..+/.test(v) || 'E-mail must be valid',
             ];
         },
         handleReset(){
          this.payload = {
                name : '',
                user_id : this.$store.getters.user.user.id,
                company_id : this.$store.getters.user.default_user_company.company_id, 
          }
         }
     },
     watch: {
         'open_modal'(newValue) {
             this.dialog = newValue;
             if(!newValue){
                this.handleReset();
                this.$refs.form.reset();
            }
         },
         'item_selected'(newValue) {
            if(newValue){
                this.payload = {
                  id : newValue.id,
                  name : newValue.name,
                  company_id : newValue.company_id,
              };
            }
             else{
                this.handleReset();
                this.$refs.form.reset()
             }
           }
     }
  }
  </script>