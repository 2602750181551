<template class="invite-user-wrapper">
    <div class="pa-3 pt-6 invite-user-wrapper">
        <p class="text-h6">Invite user</p>
        <div class="d-flex align-center" style="width: 50%;">
          <template>
            <div v-for="(item,index) in payload.emails" class="user-emails-wrapper mr-2" :key="index">
              <span class="mr-2">{{item}}</span>
              <v-btn icon @click="handleRemoveEmail(index)">
                <v-icon size="20">mdi-close-circle</v-icon>
              </v-btn>
            </div>
          </template>
          <div v-if="showAddEmail" class="text-field-add d-flex" style="width: 60%;">
              <div class="flex-grow-1">
                <v-text-field
                    ref="emailInput"
                    :class="{ 'invalid-email': isEmailValid }"
                    v-model="input_email"
                    placeholder="Enter to add the email"
                    dense
                    hide-details
                    :rules="emailAddressRules"
                    @keyup.enter="handleEnter"
                    @input="isEmailValid = false"
                    solo
                ></v-text-field>
                <span v-if="isEmailValid" style="color: red; position: absolute;">{{errormsg}}</span>
              </div>
            <v-btn class="ml-2" icon @click="showAddEmail=false">
              <v-icon>mdi-close-circle</v-icon> 
            </v-btn>
          </div>
          <v-btn  v-if="!showAddEmail" @click="handleShowAddEmail" color="default" meduim>
            New Email
          </v-btn>
        </div>
        <v-card class="pa-5 pb-10 mt-8">
          <div class="d-flex align-center">
            <label for="in-charge">
                  User Type : 
            </label>
            <v-radio-group
              v-model="payload.role"
              row
              class="ml-4"
            >
              <v-radio
                label="Regular User"
                value="RU"
              ></v-radio>
              <v-radio
                label="Manager"
                value="M"
              ></v-radio>
              <v-radio
               v-if="user.default_user_company.role == 'SA' || user.is_admin"
                label="Company Admin"
                value="CA"
              ></v-radio>
              <v-radio
               v-if="user.is_admin"
                label="Company Owner"
                value="SA"
              ></v-radio>
            </v-radio-group>
          </div>
          <hr class="mb-5"/>
          <div class="d-flex align-center" style="width: 50%;">
            <label for="in-charge">
                 Supervisor : 
            </label>
            <v-autocomplete
              class="ml-2"
              v-model="payload.parent_id"
              :items="supervisors"
              dense
              label="Select Supervisor"
              hide-details
              solo
            ></v-autocomplete>
          </div>
          
          <hr class="my-5"/>
          <!-- <div class="d-flex align-center mb-8 company-info">
            <label for="in-charge">
                  Company Name : 
            </label>
              <v-autocomplete
              class="ml-2"
              v-model="local_payload.company_id"
              :items="company_list"
              dense
              label="Select Company"
              hide-details
              solo
              :readonly="user.default_user_company.role == 'CA' ? true : false"
            ></v-autocomplete>
            <v-btn v-if="user.default_user_company.role == 'SA'" icon @click="showmodal = true">
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </div> -->
          <div class="d-flex align-center company-info" v-if="user.default_user_company.role == 'SA' && this.payload.role != 'CA'">
            <label for="in-charge">
                  Branch Name : 
            </label>
              <v-autocomplete
              class="ml-2"
              v-model="local_payload.branch_id"
              :items="branch_list"
              dense
              label="Select Branch"
              hide-details
         
              clearable
              solo
            >
            <!-- <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="remove(data.item)"
                >
                  {{ data.item.text }}
                </v-chip>
            </template> -->
          
          </v-autocomplete>
            <v-btn v-if="user.default_user_company.role == 'SA'" icon @click="showBranchModal = true">
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </div>
        </v-card>
        <v-btn :disabled="payload.emails.length ? false : true" :loading="loading" color="primary"  class="mt-5" large @click="handleSumbitInvitation">
          <v-icon size="20">mdi-plus</v-icon> Invite user
        </v-btn>
        <Company :open_modal="showmodal" @handleModalHide = "showmodal = false" @handleFetchCompany="getCompany"/>
        <Branch :open_modal="showBranchModal" :company_id="user.default_user_company.company_id" @handleModalHide = "showBranchModal = false" @handleFetchBranch ="getBranches"/>
    </div>
</template>

<script>
  import * as EmailValidator from 'email-validator';
  import { SendInvitation } from '@/api/send_invitation'
  import { payment_status } from  '@/helpers/payment_status';
  import Company from  '../modals/Company.vue';
  import Branch from  '../modals/Branch.vue';
  export default {
    components:{
        Company,
        Branch,
    },
    data () {
      return {
        errormsg : 'Invali Email Address',
        payment_status:payment_status,
        company_list:[],
        branch_list:[],
        supervisors:[],
        valid:true,
        showmodal:false,
        showBranchModal:false,
        loading:false,
        isEmailValid:false,
        input_email:null,
        search:null,
        showAddEmail:false,
        showeducationmodal:false,
        showeligibilitymodal:false,
        user : this.$store.getters.user.user,
        local_payload:{
          company_id : null,
          branch_id : null,
        },
        payload:{
          emails:[],
          role : 'RU',
          departments : 1,
          projects : [1],
          parent_id : this.$store.getters.user.user.default_user_company.user_id,
          company_id : this.$store.getters.user.user.default_user_company.company.id,
          company_name : this.$store.getters.user.user.default_user_company.company.name,
          timezone_id : this.$store.getters.user.default_user_company.company.timezone_id,
          product:null,
          product_name:null,
          site : 'bonds',
        },
        emailAddressRules: [
            v => !!v || 'Email Address is required',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
      }
    },
    mounted(){
      this.handleGetUserSubscription();

      this.getBranches();
      this.getSupervisors();
      if(this.user.default_user_company.role == 'CA'){
        this.$notiflix.Loading.arrows();
        this.getClientCompany();
      }
    },
    methods:{
        remove (item) {
          console.log(item,'item')
          const index = this.local_payload.branch_id.indexOf(item.value)
          if (index >= 0) this.local_payload.branch_id.splice(index, 1)
        },
        closedialog(){
            this.showeducationmodal = false;
            this.showeligibilitymodal = false;
        },
        handleRemoveEmail(index){
           this.payload.emails.splice(index,1);
        },
        handleShowAddEmail(){
          this.showAddEmail = true;
        },
        handleEnter(){
          if(EmailValidator.validate(this.input_email)){
            const findemail = this.payload.emails.find(item=>item === this.input_email);
            if(findemail){
              this.isEmailValid = true
              this.errormsg = 'Email address Already Exist'
            }
            else{
              this.payload.emails = [...this.payload.emails, this.input_email];
              this.input_email = null;
              this.showAddEmail = false;
            }
           
          }
          else{
            this.$awn.warning('Invalid Email Address')
            this.isEmailValid = true
          }
        },
        handleSumbitInvitation(){
          if(!this.payload.emails.length){
            return this.$awn.warning('No emails found');
          }
          if(this.user.default_user_company.role == 'SA'  && this.payload.role !='CA' && !this.local_payload.branch_id){
            return this.$awn.warning('Branch Name Required');
          }
          this.local_payload.company_id = this.user.default_user_company.company_id;
          // if(this.user.default_user_company.role == 'SA' && !this.local_payload.company_id){
          //   return this.$awn.warning('Company Name Required');
          // }

          // if(this.user.default_user_company.role == 'CA' && !this.local_payload.branch_id){
          //   return this.$awn.warning('Branch Name Required');
          // }

          this.$notiflix.Loading.arrows();
          this.loading = true;

          SendInvitation(this.payload).then((resp)=>{
            if(resp.status == 200){
              this.handleSaveUserCompany();
            }
            // this.loading = false;
          }).catch((error)=>{
            this.loading = false;
            console.log(error,'error')
          })
          .finally(() => {
            this.loading = false; // Stop loading
            this.$notiflix.Loading.remove();
          });
        },
        handleSaveUserCompany(){
           this.axios.post(`/user/save-company-details`,{...this.local_payload, emails : this.payload.emails}).then((res)=>{
              if(res.status == 200){
                this.$awn.success('Invatation Sent')
                this.payload.emails = [];
              }
           }).catch((err)=>{
                // console.log(err,'err')
           }).finally(() => {
            this.$notiflix.Loading.remove();
          });
        },
        handleGetUserSubscription(){
          if(this.$store.getters.user.user.is_admin){
            this.payload.product = 3
            this.payload.product_name = 'aetos financial';
          }
          else{
            this.axios.get(`/user/get-subscription-details/${this.$store.getters.user.user.current_user_company_id}`).then((res)=>{
              if(res){
                this.payload.product = res.data.data.product.id
                this.payload.product_name = res.data.data.product.name
              }
            }).catch((err)=>{
                  // console.log(err,'err')
            },this)
          }
        
        },
        getCompany(){
           this.axios.get(`/company`).then((res)=>{
             if(res){
               this.company_list = res.data.data.map((item, index)=>{
                  return {
                    value:item.id,
                    text:item.name,
                  }
               })
             }
           }).catch((err)=>{
                // console.log(err,'err')
           },this)
        },
        getSupervisors(){
           this.$notiflix.Loading.arrows();
           this.axios.post(`/user/supervisors`,{
                company_id :  this.user.default_user_company.company_id,
                role :  this.user.default_user_company.role,
                user_id :  this.user.default_user_company.user_id,
                id :  this.user.default_user_company.id,
           }).then((res)=>{
                this.supervisors = res.data.data.map((item)=>{
                    return {
                        value : item.user.id,
                        text : item.user.first_name + ' ' + item.user.last_name,
                    }
                })
           }).catch((err)=>{
                console.log(err,'err')
            }).finally((err)=>{
            this.$notiflix.Loading.remove()
           })
        },
        getClientCompany(){
           this.axios.get(`/user/user-company/${this.user.email}`).then((res)=>{
              if(res.status == 200){
                this.local_payload.company_id = res.data.data.company_id
                this.local_payload.branch_id = res.data.data.branch_id
              }
           }).catch((err)=>{
                console.log(err,'err')
           }).finally((err)=>{
            this.$notiflix.Loading.remove()
           })
        },
        getBranches(){
          this.axios.get(`/company/${this.user.default_user_company.company_id}`).then((res)=>{
              if(res.status == 200){
                this.branch_list = res.data.data.map((item)=>{
                  return {
                    value : item.id,
                    text : item.branch_name,
                  }
                })
              }
           }).catch((err)=>{
                console.log(err,'err')
           }).finally((err)=>{
            this.$notiflix.Loading.remove()
           })
        },
        // getBranches(data){
        //    if(data.branches.length){
        //       this.branch_list = data.branches.map((item)=>{
        //         return {
        //           value : item.id,
        //           text : item.branch_name,
        //         }
        //       })
        //    }
        // },
    },
    watch: {
         'showAddEmail'(newValue) {
             if(newValue){
                if (this.$refs.emailInput) {
                  this.$refs.emailInput.focus();
                }
             }
         }
     }
  }
</script>

<style scope>
  .invite-user-wrapper{
    background-color: #ebebeb;
    height: 100%;
  }
  .company-info{
    width: 50%;
  }
  .user-emails-wrapper{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 0px 10px;
    background-color: #ebebeb;
    border-radius: 2px;
  }
  .user-emails-wrapper span{
   color: blue;
  }
  .invalid-email{
    border: solid 2px red;
  }
</style>