<template>
  <div class="billing-user-wrapper">
    <p class="ma-0 pa-5 text-h5"><strong>Manage User</strong></p>
     
        <v-card class="pt-8 pb-8 mt-0 v-card-container">
            <div class="d-flex align-center mb-3" style="gap:5%">
                <div class="d-flex align-center search-field" v-if="user.is_admin">
                  <label>Company</label>
                  <v-autocomplete
                    class="ml-4"
                    hide-details
                    v-model="query.company"
                    :items="companies"
                    text="text"
                    id="value"
                    label="Select Company"
                    solo
                    dense
                    :readonly="user.default_user_company.role == 'CA' ? true : false"
                  ></v-autocomplete>
                </div>

                <div class="d-flex align-center search-field"  v-if="user.default_user_company.role == 'SA' && !user.is_admin">
                    <label>Branch</label>
                    <v-autocomplete
                      class="ml-4"
                      hide-details
                      v-model="query.branch"
                      :items="branch"
                      text="text"
                      id="value"
                      label="Select Branch"
                      solo
                      dense
                    ></v-autocomplete>
                </div>
                <div class="d-flex align-center search-field">
                  <label>User Type</label>
                  <v-autocomplete
                    class="ml-4"
                    hide-details
                    v-model="query.status"
                    :items="filter_user_types"
                    text="text"
                    id="value"
                    label="Select Status"
                    solo
                    dense
                  ></v-autocomplete>
                </div>
                <div class="d-flex align-center search-field">
                  <label>Status</label>
                  <v-autocomplete
                    class="ml-4"
                    hide-details
                    v-model="query.status"
                    :items="status"
                    text="text"
                    id="value"
                    label="Select Status"
                    solo
                    dense
                  ></v-autocomplete>
                </div>
            </div>
            <div class="mt-5">
              <div class="table-wrapper">
                <v-data-table
                  :loading="loading_table"
                  :headers="headers"
                  :items="user_lists"
                  :page.sync="page"
                  :items-per-page="10"
                  hide-default-footer
                  class="elevation-1"
                  @page-count="pageCount = $event"
                >
                <template v-slot:item.user.full_name="{ item }">
                    {{item.user.full_name}}
                    <br/>
                    ({{item.user.email}})
                </template>

                <template v-slot:item.role="{ item }">
                  <div class="py-4">
                    <div v-if="user.is_admin">
                      {{ handleGetRole(item) }}
                    </div>
                    <v-autocomplete v-else
                      hide-details
                      :value="handleCheckRole(item)"
                      :items="user_types"
                      text="text"
                      id="value"
                      solo
                      dense
                      @change="handleChangeUserType($event,item)"
                    ></v-autocomplete>
                  </div>
                </template>
                <template v-slot:item.company="{ item }" v-if="user.is_admin">
                  <div class="py-4">
                    <div v-if="user.is_admin">
                      {{ item.company.name }}
                    </div>
                    <!-- <v-autocomplete v-else
                      hide-details
                      :value="handleCompany(item)"
                      :items="companies"
                      text="text"
                      id="value"
                      solo
                      dense
                      @change="handleChangeCompany($event,item)"
                    ></v-autocomplete> -->
                  </div>
                </template>
                <template v-slot:item.branch="{ item }">
                  <div class="py-4">
                    <v-autocomplete
                      hide-details
                      :value="handleGetBranch(item)"
                      :items="branch"
                      text="text"
                      id="value"
                      solo
                      dense
                      @change="handleChangeBranch($event,item)"
                    ></v-autocomplete>
                  </div>
                </template>
                <template v-slot:item.supervisor="{ item }">
                  <div class="py-4">
                    <div v-if="user.is_admin">
                      {{ handleGetSuperVisor(item) }}
                    </div>
                    <v-autocomplete v-else
                      hide-details
                      :items="filterUserSuperVisors(item)"
                      :value="item.parent_id"
                      text="text"
                      id="value"
                      solo
                      dense
                      @change="handleChangeSupervisor($event,item)"
                    ></v-autocomplete>
                  </div>
                </template>

                <template v-slot:item.status="{ item }">
                    <div v-if="user.is_admin" class="manage-user-status">
                      <span :class="!item.user_other_info ? 'active' : item.user_other_info.status.toLowerCase() ">
                        {{ item.user_other_info ? item.user_other_info.status : 'Active' }}
                      </span>
                    </div>
                   <div class="manage-user-actions" v-else>
                      <span :class="handleCheckStatus(item) && 'active'" @click="!handleCheckStatus(item) && handleChangeUserStatus(item,'active')">Active</span>
                      <span :class="!handleCheckStatus(item) && 'active'" @click="handleCheckStatus(item) && handleChangeUserStatus(item,'inactive')">Inactive</span>
                   </div>
                </template>
                                                        
              
                </v-data-table>
              </div>
              <div class="text-center v-pagination-wrapper mt-5">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                ></v-pagination>
              </div>
            </div>
        </v-card>
  </div>
</template>

<script>
import { changeFormat } from '@/utils/dateHelper';
  import { UsersCompany } from '@/api/user_company'
import { payment_status } from  '@/helpers/payment_status';
import { insurance_status } from  '@/helpers/insurance_status';

export default {
  components:{
  },
  data () {
    return {
      loading_table : false,
      user : this.$store.getters.user.user,
      search:null,
      showeducationmodal:false,
      showeligibilitymodal:false,
      payment_status:payment_status,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      user_lists:[],
      supervisors:[],
      companies:[],
      branch:[],
      headers: [
        {
          text: 'ID',
          align: 'start',
          value: 'id',
        },
        { text: 'Name', value: 'user.full_name' },
        { text: 'User Type', value: 'role'},
        { text: 'Supervisor', value: 'supervisor',sortable: false },
        { text: 'Company', value: 'company',sortable: false },
        { text: 'Branch', value: 'branch',sortable: false },
        { text: 'Status', value: 'status',sortable: false },
      ],
      datas:[],
      query:{
        company:null,
        branch:null,
        status : 'all',
        user_type : 'all'
      },
      user_types:[
        {
          value:'RU',
          text:'Regular User',
        },
        {
          value:'M',
          text:'Manager',
        },
        {
          value:'CA',
          text:'Company Admin',
        },
        {
          value:'SA',
          text:'Company Owner',
        }
      ],
      filter_user_types:[
        {
          value:'all',
          text:'All',
        },
        {
          value:'RU',
          text:'Regular User',
        },
        {
          value:'M',
          text:'Manager',
        },
        {
          value:'CA',
          text:'Company Admin',
        }
      ],
      status:[
        {
          value:'all',
          text:'All',
        },
        {
          value:'active',
          text:'Active',
        },
        {
          value:'inactive',
          text:'Inactive',
        },
      ],
    }
  },

  created(){   
    if(this.$store.getters.user.user.is_admin){
      const header = this.headers.filter(header => {
        return header.value && header.value !== 'branch'
      });
      return this.headers = header;
    }
      // return this.getCurrentCompany();
    if(this.$store.getters.user.user.default_user_company.role == 'SA'){
      const user_type = this.user_types.filter(header => {
        return header.value !== 'SA'
      });
      this.user_types = user_type;
      return this.filteredHeaders();
      // return this.getCurrentCompany();
    }

    if(this.$store.getters.user.user.default_user_company.role == 'CA'){
      const user_type = this.user_types.filter(header => {
        return header.value !== 'CA' && header.value !== 'SA'
      });
      this.user_types = user_type;

      const header = this.headers.filter(header => {
        return header.value !== 'company' && header.value !== 'branch'
      });
      return this.headers = header;
    }
  } , 

  mounted(){
    this.getAllUsers();
  },

  methods:{
      filteredHeaders() {
          const header = this.headers.filter(header => {
            return header.value !== 'company'
          });
          console.log('test')
          this.headers = header;
      },
      filterUserSuperVisors(item) {
          const user_type = this.supervisors.filter(items => {
            if(item.role == 'RU'){
              return items.role != 'RU';
            }
            if(item.role == 'M'){
              return items.role == 'CA' || items.role == 'M' || items.role == 'CA';
            }
            if(item.role == 'CA'){
              return items.role == 'CA' || items.role == 'SA';
            }
          });
          return user_type;
      },
      handleGetSuperVisor(item) {
          const supervisor = this.supervisors.find(items => items.value === item.parent_id);
          if(supervisor){
            return supervisor.text;
          }
          return 'N/A';
      },
      handleGetPaymentStatus(id) {
       const status_payment = payment_status.find(item=>item.value == id);
       return status_payment.text
      },
      handleCheckRole(item) {
        if(item.user_other_info){
          return item.user_other_info.role;
        }
        return item.role;
      },
      handleGetRole(item) {
        const role = this.user_types.find(items => items.value == item.role)
        return role.text;
      },
      handleCompany(item) {
        if(item.user.client_company){
          return item.user.client_company.company_id;
        }
        return null;
      },

      handleGetBranch(item) {
        if(item.user.client_company){
          return item.user.client_company.branch_id;
        }
        return null;
      },
      handleCheckStatus(item) {
        if(item.user_other_info){
          return item.user_other_info.status == 'active' ? true : false;
        }
        return item.status == 'active' ? true : false;
      },

      handleChangeUserType(value, item) {
      //  if(value != 'CA'){
      //     var params = {
      //       ...item,
      //       role : value,
      //     }
      //     this.handleChangeUserStatus(params,item.status)
      //  }
      //  else{
      // }
      var data ={
        id:item.id,
        role:value,
      }
      this.handleChangeInfo(data, item, this.$store.getters.token)

      },
      handleChangeSupervisor(value, item) {
          var data ={
            id:item.id,
            parent_id:value,
          }
          this.handleChangeInfo(data, item, this.$store.getters.token, true)
      },

      handleChangeInfo(data, item, token, update_supervisor = false) {
        this.$notiflix.Loading.arrows();
        UsersCompany(data, 'put', token).then((resp)=>{
            if(resp.status == 200){
              this.$awn.success(`${update_supervisor ? 'Superivosr' : 'Role'} updated Successfully`)
              this.handleDeleteLocalOtherInfo(item);
            }
        }).catch((error)=>{
          this.loading = false;
          console.log(error,'error')
        })
        .finally(() => {
          this.$notiflix.Loading.remove();
        });
      },

      handleChangeBranch(val, item) {
        this.$notiflix.Loading.arrows();
        this.axios.post(`/user/change-branch`,{
            user :  item.user,
            branch_id : val,
        }).then((res)=>{
          if(res.status == 200){
            this.$awn.success('Status updated Successfully')
            this.getAllUsers();
          }
        }).catch((err)=>{
            this.$notiflix.Loading.remove();
            console.log(err,'err')
        }).finally(()=>{
          this.$notiflix.Loading.remove();
        },this)
      },

      handleChangeCompany(val, item) {
        this.$notiflix.Loading.arrows();
        this.axios.post(`/user/change-company`,{
            user :  item.user,
            company : val,
        }).then((res)=>{
          if(res.status == 200){
            this.$awn.success('Status updated Successfully')
            this.getAllUsers();
          }
        }).catch((err)=>{
            this.$notiflix.Loading.remove();
            console.log(err,'err')
        }).finally(()=>{
          this.$notiflix.Loading.remove();
        },this)
      },

      handleDeleteLocalOtherInfo(data) {
        this.axios.post(`/user/delete-other-info`,{
            user :  data,
        }).then((res)=>{
          if(res.status == 200){
            // this.$awn.success('Status updated Successfully')
            this.getAllUsers();
          }
        }).catch((err)=>{
            this.$notiflix.Loading.remove();
            console.log(err,'err')
        }).finally(()=>{
          this.$notiflix.Loading.remove();
        },this)
      },

      handleGetInsuranceStatus(id) {
       const status = insurance_status.find(item=>item.value == id);
       return status.text
      },

      handleChangeUserStatus(item, type){
           this.$notiflix.Loading.arrows();
           this.axios.post(`/user/update-user-other-info`,{
                user :  item,
                type :  type,
           }).then((res)=>{
              if(res.status == 200){
                this.$awn.success('Status updated Successfully')
                this.getAllUsers();
              }
           }).catch((err)=>{
               this.$notiflix.Loading.remove();
                console.log(err,'err')
           }).finally(()=>{
              this.$notiflix.Loading.remove();
           },this)
     },

      goTo(id){
          this.$router.push(`/application/${id}`)
      },
      getAllUsers(){
           this.axios.post(`/user/manage-user`,{
                company_id :  this.user.default_user_company.company_id,
                role :  this.user.default_user_company.role,
                user_id :  this.user.default_user_company.user_id,
                id :  this.user.default_user_company.id,
           }).then((res)=>{
               this.user_lists = res.data.data;
               this.supervisors = res.data.all_user.map((item)=>{
                  return{
                    value:item.user.id,
                    text:item.user.full_name + ` (${item.role})`,
                    role:item.role,
                  }
               });
               this.companies = res.data.company.map((item)=>{
                  return{
                    value:item.id,
                    text:item.name,
                  }
               });
               this.branch = res.data.branch.map((item)=>{
                  return{
                    value:item.id,
                    text:item.branch_name,
                  }
               });
           }).catch((err)=>{
                console.log(err,'err')
           })
      },
      getCurrentCompany(){
           this.axios.get(`/user/get-current-company/${this.user.email}`).then((res)=>{
              if(res.status == 200){
                this.query.company = res.data.company.company_id
              }
           }).catch((err)=>{
                console.log(err,'err')
           })
      },
      
  }
}
</script>

<style scope>
 .billing-user-wrapper{
  background-color: #cdf4f6;
    height: 100%;
  }
  .table-wrapper{
    background: #fff;
    padding: 15px;
  }
  .manage-user-status span{
    color: #fff;
    padding: 7px;
    border-radius: 10px;
    text-transform: capitalize;
  }
  .manage-user-status span.active{
    background: green;
  }
  .manage-user-status span.inactive{
    background: red;
  }
</style>