<template>
    <v-row justify="center">
     <v-dialog
       v-model="dialog"
       persistent
       top
       max-width="600px"
       @input="checkDialogClose"
     >
     
       <v-card>
         <v-card-title class="text-h5 grey lighten-2 d-flex justify-lg-space-between">
           <div>
              Branch
           </div>
           <v-btn icon @click="handleCloseDialog">
             <v-icon>mdi-close</v-icon>
         </v-btn>
         </v-card-title>
         <v-card-text class="px-4 pb-0 mt-4">
             <v-form
                 ref="form"
                 v-model="valid"
                 lazy-validation
             >
                <v-text-field
                    v-model="payload.branch_name"
                    label="Name"
                    outlined
                    required
                    :rules="nameRules"
                ></v-text-field>
             </v-form>
         </v-card-text>
         <v-card-actions  class="grey lighten-2">
           <v-spacer></v-spacer>
           <v-btn
             depressed
             color="primary"
             @click="handleSumbit"
             >
             Add
             </v-btn>
         </v-card-actions>
       </v-card>
     </v-dialog>
   </v-row>
   </template>
  
  <script>
  export default {
     props: {
         open_modal: {
             type: Boolean,
         },
         company_id: {
             type: Number,
         },
     },
     data () {
         return {
             dialog: false,
             valid: true,
             nameRules: [
                 v => !!v || 'Branch Name is required',
             ],
             payload : {
                  branch_name : '',
                  company_id : null,
                  user_id : this.$store.getters.user.user.id,
             }
         }
     },
     methods:{
         handleSumbit() {
             if(this.$refs.form.validate()){
                 this.axios.post('/company/branch',{...this.payload, company_id: this.company_id}).then((res)=>{
                    this.$awn.success('Branch Added Successfully');
                     if(res){
                         this.$emit('handleModalHide');
                         this.$emit('handleFetchBranch');
                         this.handleReset();
                     }
                 }).catch((error)=>{
                     console.log(error,'error')
                 })
             }
         },
         checkDialogClose(val) {
             if(!val){
                 this.$emit('handleModalHide')
             }
         },
         handleCloseDialog() {
                 this.$emit('handleModalHide')
         },
         handleValidation(type){
             return [
                 v => !!v || `${type} is required`,
                 v =>  /.+@.+\..+/.test(v) || 'E-mail must be valid',
             ];
         },
         handleReset(){
          this.payload = {
              branch_name : '',
              user_id : this.$store.getters.user.user.id,
          }
         }
     },
     watch: {
         'open_modal'(newValue) {
             this.dialog = newValue;
         },
        //  'company_id'(newValue) {
        //      this.payload.company_id = newValue;
        //  },
         'item_billing_selected'(newValue) {
              this.payload = {
                  id : newValue.id,
                  company_name : newValue.company_name,
                  email_address : newValue.email_address,
                  user_id : this.$store.getters.user.user.id,
              };
           }
     }
  }
  </script>