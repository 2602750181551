<template>
    <v-row justify="center">
     <v-dialog
       v-model="dialog"
       persistent
       top
       max-width="600px"
       @input="checkDialogClose"
     >
     
       <v-card>
         <v-card-title class="text-h5 grey lighten-2 d-flex justify-lg-space-between">
           <div>
                Export
           </div>
           <v-btn icon @click="handleCloseDialog">
             <v-icon>mdi-close</v-icon>
         </v-btn>
         </v-card-title>
         <v-card-text class="py-6">
            <div class="d-flex" style="gap: 10px;">
                <v-menu
                    v-model="menuFrom"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        class="date-picker-input"
                        v-model="payload.dateFrom"
                        label="From"
                        readonly
                        dense
                        outlined
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="payload.dateFrom"
                    no-title
                    @input="menuFrom = false"
                    ></v-date-picker>
                </v-menu>
                <v-menu
                    v-model="menuTo"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        class="date-picker-input"
                        v-model="payload.dateTo"
                        label="To"
                        readonly
                        dense
                        outlined
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="payload.dateTo"
                    no-title
                    @input="menuTo = false"
                    ></v-date-picker>
                </v-menu>
            </div>
         </v-card-text>
         <v-card-actions class="text-h5 grey lighten-2 d-flex justify-lg-space-between">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="handleExport">
                <v-icon class="mr-1">mdi-export</v-icon> Export
            </v-btn>
         </v-card-actions>
       </v-card>
     </v-dialog>
   </v-row>
   </template>
  
  <script>
  import moment from 'moment';
  export default {
     props: {
         open_modal: {
             type: Boolean,
         },
         logs_selected: {}
     },
     data () {
         return {
          
             menuTo: false,
             menuFrom: false,
             dialog: false,
             data: null,
             valid: true,
             nameRules: [
                 v => !!v || 'Branch Name is required',
             ],
             payload : {
                dateTo: moment().format('YYYY-MM-DD'),
                dateFrom: moment().subtract(7,'d').format('YYYY-MM-DD'),
             }
         }
     },
     methods:{
         checkDialogClose(val) {
             if(!val){
                 this.$emit('handleModalHide')
             }
         },
         handleCloseDialog() {
                 this.$emit('handleModalHide')
         },
         handleExport() {
                 this.$emit('handleExport',this.payload)
         },
         handleValidation(type){
             return [
                 v => !!v || `${type} is required`,
                 v =>  /.+@.+\..+/.test(v) || 'E-mail must be valid',
             ];
         },
     },
     watch: {
         'open_modal'(newValue) {
             this.dialog = newValue;
         },
     }
  }
  </script>

  <style lang="scss" scoped>
  .v-card__text *{
    color: #000 !important;
    div #wrapper .table *{
        font-size: 14px !important;
    }

  }
  </style>