<template>
    <div class="admin-setting-wraper">
            <div class="d-flex py-5 align-center">
                <p class="ma-0 text-h5 mr-5"><strong>Settings</strong></p>
            </div>
          <!-- <v-card class="mt-0 mb-5">
            <v-card-title>
                <div class="text-h6">
                    <strong>Billing Details</strong>
                </div>
            </v-card-title>
            <hr class="mx-2"/>
            <div>
                <div class="text-h6">
                    <strong>Default Billing</strong>
                </div>
            </div>
            <v-card-text>
                <div>
                    <v-data-table
                        :loading="loading_table"
                        :headers="headers"
                        :items="datas.data"
                        :page.sync="page"
                        :items-per-page="10"
                        hide-default-footer
                        class="elevation-1"
                        @page-count="pageCount = $event"
                    >
                    <template v-slot:item.id="{ item, index }">
                        {{ index + 1 }}
                    </template>
                    <template v-slot:item.created_at="{ item, index }">
                        {{ formattedDate(item.created_at) }}
                    </template>
                    <template v-slot:item.company="{ item, index }">
                        {{item.company.name}}
                    </template>
                    <template v-slot:item.email="{ item, index }">
                        {{item.billing.email_address}}
                    </template>

    
                    <template v-slot:item.action="{ item }">
                        <v-btn color="primary" icon  @click="handleShowLogs(item)">
                            <v-icon size="18">mdi-eye</v-icon>
                        </v-btn> 
                    </template>
                                                            
                    
                    </v-data-table>
                </div>
                <div class="text-center mt-5 elevation-3 grey lighten-2">
                    <v-pagination
                        v-model="page"
                        @input="handlePageChange"
                        :length="datas.last_page"
                    ></v-pagination>
                </div>
            </v-card-text>
          </v-card> -->
          <v-card class="mt-0">
            <v-card-title class="d-flex justify-lg-space-between">
                <div class="text-h6">
                    <strong>Billing</strong>
                </div>
                <v-btn rounded small color="secondary" @click="billing_modal = true">
                    <v-icon size="12" class="mr-1">mdi-plus-circle</v-icon>New
                </v-btn>
            </v-card-title>
            <hr class="mx-2"/>
            <v-card-text>
                    <div class="radio-table-container">
                            <v-radio-group
                                v-model="payload.default_billing"
                                row>
                                <div class="radio-wrapper-table" v-for="(item, index) in billing_info" :key="index">
                                    <div class="d-flex justify-lg-space-between mb-2">
                                        <v-radio
                                            :value="item.id"
                                        ></v-radio>
                                        <div class="actions">
                                            <v-btn icon @click="handleEditBillingAddress(item)">
                                                <v-icon size="20" color="#000">mdi-pencil</v-icon>
                                            </v-btn>
                                            <v-btn icon @click="handleDeleteBillingAddress(item)">
                                                <v-icon size="20" color="red">mdi-delete</v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                    <div class="hard-copy-table">
                                        <table class="table table-bordered">
                                            <tbody >
                                                <tr>
                                                    <td>Company Name</td>
                                                    <td><strong>{{item.company_name}}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>Email Address.  </td>
                                                    <td><strong>{{item.email_address}}</strong></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </v-radio-group>
                    </div>
                <!-- <div>
                    <v-data-table
                        :loading="loading_table"
                        :headers="headers"
                        :items="datas.data"
                        :page.sync="page"
                        :items-per-page="10"
                        hide-default-footer
                        show-select
                        class="elevation-1"
                        @page-count="pageCount = $event"
                    >
                    <template v-slot:item.id="{ item, index }">
                        {{ index + 1 }}
                    </template>
                    <template v-slot:item.created_at="{ item, index }">
                        {{ formattedDate(item.created_at) }}
                    </template>
                    <template v-slot:item.company="{ item, index }">
                        {{item.company.name}}
                    </template>
                    <template v-slot:item.email="{ item, index }">
                        {{item.billing.email_address}}
                    </template>

    
                    <template v-slot:item.action="{ item }">
                        <v-btn color="primary" icon  @click="handleShowLogs(item)">
                            <v-icon size="18">mdi-eye</v-icon>
                        </v-btn> 
                    </template>
                                                            
                    
                    </v-data-table>
                </div>
                <div class="text-center mt-5 elevation-3 grey lighten-2">
                    <v-pagination
                        v-model="page"
                        @input="handlePageChange"
                        :length="datas.last_page"
                    ></v-pagination>
                </div> -->
            </v-card-text>
            <v-card-actions class="grey lighten-4">
                <v-spacer/>
                <v-btn color="primary" @click="handleUpdateDefaultSelectedBilling" :loading="submitLoading">
                    Update
                </v-btn>
            </v-card-actions>
          </v-card>
          <BillingAddress :open_modal="billing_modal" :selected_item = "item_billing_selected" @handleFetchBilling = "getBillingInfo" @handleModalHide = "billing_modal = false, item_billing_selected = []"/>
    </div>
  </template>
  
  <script>
  import { changeFormat } from '@/utils/dateHelper';
  import { payment_status } from  '@/helpers/payment_status';
  import { insurance_status } from  '@/helpers/insurance_status';
  import BillingAddress from  '../modals/billing_address.vue';
  export default {
    components:{
        BillingAddress,
    },
    data () {
      return {
        mail_logs_modal : false,
        submitLoading : false,
        billing_modal : false,
        logs_selected : [],
        billing_info : [],
        item_billing_selected : [],
        selected : [],
        dateFrom: null,
        dateTo: null,
        menuFrom: false,
        menuTo: false,
        loading_table : false,
        user : this.$store.getters.user.user,
        search:null,
        showeducationmodal:false,
        showeligibilitymodal:false,
        payment_status:payment_status,
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        headers: [
          { text: 'Date and Time', value: 'created_at' },
          { text: 'Company', value: 'company' },
          { text: 'Email', value: 'email' },
          { text: 'Amount', value: 'amount' },
          { 
            text: 'ACTION', 
            value: 'action', 
            sortable: false,
            width: '20', 
          },
        ],
        datas:[],                             
        companies:[],
        payload:{
            default_billing : null,
            company_id : this.$store.getters.user.user.default_user_company.company_id,
        },
        query:{
          company : null,
          payment_status : null,
        },
      }
    },
  
    mounted(){
      this.getBillingInfo();
    },
  
    methods:{
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },

        formattedDate(date) {
          return changeFormat(date); // Example format string
        },
        handleGetPaymentStatus(id) {
         const status_payment = payment_status.find(item=>item.value == id);
         return status_payment.text
        },
        handlePageChange() {
          this.getBillingLogs()
        },
        handleShowLogs(item) {
          this.logs_selected = item;
          this.mail_logs_modal = true;
        },
        handeSendSuccess() {
          this.$awn.success('Bill Resend Successfully'),
          this.getBillingLogs();
          this.logs_selected = [];
          this.mail_logs_modal = false;
        },
        handleDeleteBillingDetails(item){
            this.axios.post('/billing',this.payload).then((res)=>{
                if(res){
                    this.loading = false
                    this.$awn.success(`${this.payload.id ? 'Update' : 'Added'} Successfully`);
                    this.$emit('handleModalHide');
                    this.$emit('handleFetchBilling');
                    this.handleReset();
                }
            }).catch((error)=>{
                console.log(error,'error')
            }).finally((fin)=>{
                this.loading = false
            })
        },
        handleUpdateDefaultSelectedBilling(item){
            this.$notiflix.Loading.arrows();
            this.submitLoading = true;
            this.axios.post('/billings/update-default-billing',this.payload).then((res)=>{
                this.$awn.success(`Default Billing Update Successfully`);
                this.getBillingInfo();
            }).catch((error)=>{
                console.log(error,'error')
            }).finally((fin)=>{
                this.submitLoading = false;
            })
        },
        handleDeleteBillingAddress(item){
            if(item.request.length){
                return this.$notiflix.Report.failure(
                    'Warning',
                    '<h5>You can’t delete this billing because it is currently in use.</h5>',
                    'Okay',
                );
            }
            else{
                this.$notiflix.Confirm.show(
                    'Delete',
                    'Are you sure, you want to delete this billing?',
                    'Yes',
                    'No',
                    () => {
                        this.deleteBilling(item)
                    },
                );
            }
           
        },

        deleteBilling(item){
            this.$notiflix.Loading.arrows();
            this.submitLoading = true;
            this.axios.delete(`/billing/${item.id}`).then((res)=>{
                this.$awn.success(`Deleted Successfully`);
                this.getBillingInfo();
            }).catch((error)=>{
                console.log(error,'error')
            }).finally((fin)=>{
                this.submitLoading = false;
            })
        },

        handleEditBillingAddress(item){
            this.item_billing_selected = item;
            this.billing_modal = true
        },

        handleGetInsuranceStatus(id) {
         const status = insurance_status.find(item=>item.value == id);
         return status.text
        },
        goTo(id){
            this.$router.push(`/application/${id}`)
        },
        goToSumarry(item){
            this.$router.push(`/billing/${item.user.client_company.company_id}/${item.billing_id}`)
        },
        getBillingInfo(){
            this.billing_info = [];
            this.axios.get(`/billing?company_id=${this.user.default_user_company.company_id}`).then((res)=>{
                const default_billing = res.data.data.find(item => item.pre_selected);
                if(default_billing){
                    this.payload.default_billing = default_billing.id
                }
                this.billing_info = res.data.data.slice().sort((a, b) => b.pre_selected - a.pre_selected);
           }).catch((err)=>{
                console.log(err,'err')
           }).finally((err)=>{
            this.$notiflix.Loading.remove();
           })
        },
    },
//     watch: {
//        'billing_info'(newValue) {
//             const default_billing = newValue.find(item => item.pre_selected);
//             this.payload.default_billing = default_billing.id
//        },
//    }

  }
  </script>
  
  <style lang="scss" scoped>
    .admin-setting-wraper{
        background-color: #ebebeb;;
        height: 100%;
        padding: 0 15px;

        .v-card{
            border-radius: 5px;
        }
    }
    table {
        width: 100%;
        border-collapse: collapse;
    }
    td, th {
        border: 1px solid #ddd;
        padding: 8px;
        width: fit-content;
        font-size: 14px !important;
    }
    tr:nth-child(even) {
        background-color: #f2f2f2;
    }
    th {
        background-color: #4CAF50;
        color: white;
    }
  </style>