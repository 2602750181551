<template>
  <div>
      <!-- <BillingAetos v-if="user.is_admin"/> -->
      <BillingClient/>
  </div>
</template>

<script>
import BillingClient from  '../pages/BillingClient.vue';
export default {
  components:{
    BillingClient
  },
  data () {
    return {
      user : this.$store.getters.user.user,
    }
  },

}
</script>