<template>
    <v-row justify="center">
     <v-dialog
       v-model="dialog"
       persistent
       top
       max-width="600px"
       @input="checkDialogClose"
     >
     
       <v-card>
         <v-card-title class="text-h5 grey lighten-2 d-flex justify-lg-space-between">
           <div>
               Mailing Address
           </div>
           <v-btn icon @click="handleCloseDialog">
             <v-icon>mdi-close</v-icon>
         </v-btn>
         </v-card-title>
         <v-card-text class="px-4 mt-4 pb-0">
             <v-form
                 ref="form"
                 v-model="valid"
                 lazy-validation
             >
             <v-text-field
                 v-model="payload.name"
                 label="Complete Name"
                 outlined
                 required
                 :rules="handleValidation('Name')"
             ></v-text-field>
             <v-text-field
                 label="Contact No."
                 v-model="payload.contact_no"
                 outlined
                 required
                  :rules="handleValidation('Contact No.')"
             ></v-text-field>
             <v-text-field
                 label="Complete Mailing Address"
                 v-model="payload.address"
                 outlined
                 required
                  :rules="handleValidation('Address')"
             ></v-text-field>
             </v-form>
         </v-card-text>
         <v-card-actions  class="grey lighten-2">
           <v-spacer></v-spacer>
           <v-btn
             depressed
             color="primary"
             @click="handleSumbit"
             >
             {{payload.id !== undefined ? `Update` : 'Add'}}
             </v-btn>
         </v-card-actions>
       </v-card>
     </v-dialog>
   </v-row>
   </template>
  
  <script>
  export default {
     props: {
         open_modal: {
             type: Boolean,
         },
         item_mailing_selected: {
             type: [],
         }
     },
     data () {
      return {
        valid: true,
        dialog: false,
        payload : {
            name : '',
            contact_no : '',
            address : '',
            user_id : this.$store.getters.user.user.id,
        },
      }
    },
    methods:{
         handleSumbit() {
             if(this.$refs.form.validate()){
                 this.axios.post('/mailing',this.payload).then((res)=>{
                     if(res){
                         this.$awn.success('Added Successfully');
                         this.$emit('handleModalHide');
                         this.$emit('handleFetchMailing');
                         this.handleReset();
                     }
                 }).catch((error)=>{
                     console.log(error,'error')
                 })
             }
         },
         checkDialogClose(val) {
             if(!val){
                 this.$emit('handleModalHide')
                 this.handleReset();
             }
         },
         handleCloseDialog() {
                this.$emit('handleModalHide')
                this.handleReset();
         },
         handleValidation(type){
             return [
                 v => !!v || `${type} is required`,
             ];
         },
         handleReset(){
            this.payload =  {
                name : '',
                contact_no : '',
                address : '',
                user_id : this.$store.getters.user.user.id,
            };
            this.$refs.form.resetValidation()
         }
     },
     watch: {
         'open_modal'(newValue) {
             this.dialog = newValue;
         },
         'item_mailing_selected'(newValue) {
            this.payload = {
                id : newValue.id,
                name : newValue.name,
                contact_no : newValue.contact_no,
                address : newValue.address,
                user_id : this.$store.getters.user.user.id,
            };
         }
     }
  }
  </script>