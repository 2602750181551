import request from '@/utils/request'
const API = '/api/userscompanies'

export function UsersCompany(params = {}, method = 'get', token) {
  var url = params.id ? API + '/' + params.id : API

  if (method === 'post' || method === 'put' || method === 'delete') {
    return request({
      url: url,
      method: method,
      data: params,
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
  }

  return request({
    url: url,
    method: method,
    params
  })
}

