import router from './router'
import store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'

const whiteList = ['/login', '/adminlogin', '/forgot_password', '/verify_token', '/verify_social_oauth', '/web/verify']

router.beforeEach((to, from, next) => {
  NProgress.start()
  if (getToken()) {
        store.dispatch('GetInfo').then(res => {
           store.dispatch('GetUserOtherInfo').then(() => {
                // next()
                next()
            })
        }).catch((err) => {
            store.dispatch('FedLogOut').then(() => {
                next({ path: '/login' })
            })
        })
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
        next()
    } else {
        next(`/login`)
        NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
