import request from '@/utils/request'
const ETOUSOFT_URL = process.env.VUE_APP_ETOUSOFT_API_URL
const API = '/api/verify'

export function EtousoftVerify(params = {}) {
  var url = API + '/' + params.id
  return request({
    url: ETOUSOFT_URL + url,
    method: 'GET',
    data: params,
    headers: {
      'Authorization': 'Bearer ' + params.token
    }
  })
}
