<template>
  <div class="d-flex align-center">
      <div class="mr-4 user-info-wrapper" v-if="!user.is_admin">
        <!-- <span>{{other_info.user.client_company ? other_info.user.client_company.company.name : user.default_user_company.company.name}}</span> -->
        <span>{{user.default_user_company.company.name}}</span>
        |
        <span>Role : {{handleFetchRole(user.default_user_company.role)}}</span>
        <!-- <span>Role : {{user.default_user_company.role == 'SA' ?  handleFetchRole(user.default_user_company.role) :  handleFetchRole(other_info.role)}}</span> -->
      </div>
      <div class="mr-4 user-info-wrapper" v-else>
        <span>{{user.default_user_company.company.name}}</span>
        |
        <span>Role : Aetos</span>
      </div>
      <h3 class="mr-2">{{user.first_name}}</h3> 
      <v-menu
        transition="slide-x-transition"
        bottom
        right
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon size="40">mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <v-card
          class="mx-auto"
          width="256"
          tile
        >
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-h6">
                    {{user.first_name}} {{user.last_name}}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{user.email}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list nav dense>
                <v-list-item link @click="handleLogout">
                  <v-list-item-icon>
                    <v-icon>mdi-logout</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Logout</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
      
      </v-menu>
  </div>
  </template>

<script>
export default {
  data: () => ({
    selectedItem: 0,
    user : [],
    other_info : [],
    items: [
      { text: 'My Files', icon: 'mdi-folder' },
      { text: 'Shared with me', icon: 'mdi-account-multiple' },
      { text: 'Starred', icon: 'mdi-star' },
      { text: 'Recent', icon: 'mdi-history' },
      { text: 'Offline', icon: 'mdi-check-circle' },
      { text: 'Uploads', icon: 'mdi-upload' },
      { text: 'Backups', icon: 'mdi-cloud-upload' },
    ],
    user_types:[
        {
          value:'SA',
          text:'Company Owner',
        },
        {
          value:'RU',
          text:'Regular User',
        },
        {
          value:'M',
          text:'Manager',
        },
        {
          value:'CA',
          text:'Company Admin',
        }
    ],
  }),
  created(){
    this.user = this.$store.getters.user.user;
    this.other_info = this.$store.getters.user.other_info;
  },
  methods:{
    handleLogout(){
      this.$store.dispatch('FedLogOut').then(() => {
        if(this.user.is_admin){
          return this.$router.push('/admin/login')
        }
        this.$router.push('/login')
      })
    },
    handleFetchRole(role){
      const user_role = this.user_types.find(item=>item.value == role)
      return user_role.text
    }
  }
}
</script>