<template>
  <div class="billing-user-wrapper">
    <p class="ma-0 pa-5 text-h5"><strong>Billing</strong></p>
     
        <v-card class="pt-8 pb-8 mt-0 v-card-container">
            <div class="d-flex align-center mb-3 justify-lg-space-between" style="gap:5%">
                <div class="d-flex align-center search-field" v-if="user.is_admin">
                  <label>Company</label>
                  <v-autocomplete
                    class="ml-4"
                    hide-details
                    :items="companies"
                    v-model="query.company"
                    text="text"
                    id="value"
                    label="Select Company"
                    solo
                    dense
                    clearable
                    @change="getBilling()"
                  ></v-autocomplete>
                </div>
                <div class="d-flex align-center search-field">
                  <label>Status</label>
                  <v-autocomplete
                    class="ml-4"
                    hide-details
                    :items="payment_status"
                    v-model="query.payment_status"
                    text="text"
                    id="value"
                    label="Select Status"
                    solo
                    dense
                    clearable
                    @change="getBilling()"
                  ></v-autocomplete>
                </div>
                <div class="d-flex align-center btn-logs"  v-if="user.is_admin">
                  <v-btn color="primary"  @click="goToLogs()">
                     Logs
                  </v-btn> 
                </div>
            </div>
            <div class="mt-5">
              <div class="table-wrapper">
                <v-data-table
                  :loading="loading_table"
                  :headers="headers"
                  :items="datas.data"
                  :page.sync="page"
                  :items-per-page="10"
                  hide-default-footer
                  class="elevation-1"
                  @page-count="pageCount = $event"
                >
                <template v-slot:item.id="{ item, index }">
                    {{ index + 1 }}
                </template>
                <template v-slot:item.company="{ item, index }">
                    {{ item.user.client_company && item.user.client_company.company.name }}
                </template>

                <template v-slot:item.in_charge="{ item }">
                    {{ item.in_charge.first_name }} {{ item.in_charge.last_name }}
               </template>

              <template v-slot:item.billing_email="{ item }">
                  {{item.billing.email_address}}
                </template>

                <template v-slot:item.total_amount="{ item }">
                  {{(formatNumber(parseInt(item.noa_amount) + parseInt(item.insured_amount) + ( item.net_premium && parseInt(item.net_premium))))}}
                </template>
                <template v-slot:item.custom_total="{ item }">
                  {{formatNumber(item.custom_total)}}
                </template>
                <template v-slot:item.payment_status="{ item }">
                    {{ handleGetPaymentStatus(item.payment_status) }}
                </template>

                <template v-slot:item.action="{ item }">
                    <v-btn color="primary" icon  @click="goToSumarry(item)">
                      <v-icon size="18">mdi-eye</v-icon>
                    </v-btn> 
                </template>
                                                        
              
                </v-data-table>
              </div>
              <div class="text-center v-pagination-wrapper mt-5">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                ></v-pagination>
              </div>
            </div>
        </v-card>
  </div>
</template>

<script>
import { changeFormat } from '@/utils/dateHelper';
import { payment_status } from  '@/helpers/payment_status';
import { insurance_status } from  '@/helpers/insurance_status';

export default {
  components:{
  },
  data () {
    return {
      loading_table : false,
      user : this.$store.getters.user.user,
      search:null,
      showeducationmodal:false,
      showeligibilitymodal:false,
      payment_status:payment_status,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'COMPANY NAME', value: 'company' },
        { text: 'EMAIL', value: 'billing_email' },
        { text: 'AMOUNT', value: 'custom_total' },
        { text: 'STATUS', value: 'payment_status' },
        { 
          text: 'ACTION', 
          value: 'action', 
          sortable: false,
          width: '20', 
        },
      ],
      datas:[],                             
      companies:[],
      query:{
        company : null,
        payment_status : null,
      },
    }
  },

  mounted(){
    this.getBilling();
    this.getCompany();
  },

  methods:{
      formatNumber(value) {
            if (typeof value !== 'string') {
                value = String(value || ''); // Ensure value is a string
            }
              const [integerPart, decimalPart] = value.split('.');
              const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
       },
      formattedDate(date) {
        return changeFormat(date); // Example format string
      },
      handleGetPaymentStatus(id) {
       const status_payment = payment_status.find(item=>item.value == id);
       return status_payment.text
      },
      handleGetInsuranceStatus(id) {
       const status = insurance_status.find(item=>item.value == id);
       return status.text
      },
      goTo(id){
          this.$router.push(`/application/${id}`)
      },
      goToLogs(){
          this.$router.push(`/billing/logs`)
      },
      goToSumarry(item){
          this.$router.push(`/billing/${item.user.client_company.company_id}/${item.billing_id}`)
      },
      getBilling(){
          this.$notiflix.Loading.arrows();
           this.axios.get(`/request?user_company_id=${this.user.current_user_company_id}&query=${JSON.stringify(this.query)}`).then((res)=>{
              console.log(res.data,'res.data')
                this.datas = res.data.data
           }).catch((err)=>{
                console.log(err,'err')
           }).finally(()=>{
            this.$notiflix.Loading.remove();
           })
           
      },
      getCompany(){
          this.$notiflix.Loading.arrows();
           this.axios.get(`/user/get-company`).then((res)=>{
           this.companies = res.data.company.map((item)=>{
                return{
                  value:item.id,
                  text:item.name,
                }
            });
           }).catch((err)=>{
                console.log(err,'err')
           }).finally(()=>{
            this.$notiflix.Loading.remove();
           })
      },
  }
}
</script>

<style scope>
 .billing-user-wrapper{
  background-color: #cdf4f6;
    height: 100%;
  }
  .table-wrapper{
    background: #fff;
    padding: 15px;
  }
</style>