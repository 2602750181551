<template>
    <div>
        <v-card class="p-5 v-card-container">
            <div v-if="summary" class="filter-wrapper d-flex align-center justify-lg-space-between">
              <div class="d-flex align-center flex-grow-1 justify-lg-space-between summary-wrapper" @click="handleFilterInsuranceStatus(2)">
                <h5>Submitted to Aetos</h5>
                <span>{{summary.submitted_to_aetos}}</span>
              </div>
              <div class="d-flex align-center flex-grow-1 justify-lg-space-between summary-wrapper" @click="handleFilterInsuranceStatus(4)">
                <h5>issued - For Delivery</h5>
                <span>{{summary['issued_for_delivery']}}</span>
              </div>
              <div class="d-flex align-center flex-grow-1 justify-lg-space-between summary-wrapper" @click="handleFilterpaymentStatus(1)">
                <h5>Unpaid</h5>
                <span>{{summary['unpaid']}}</span>
              </div>
              <div class="d-flex align-center flex-grow-1 justify-lg-space-between summary-wrapper" @click="handleFilterpaymentStatus(3)">
                <h5>for refund</h5>
                <span>{{summary['for_refund']}}</span>
              </div>
              <!-- <div class="d-flex align-center flex-grow-1 justify-lg-space-between summary-wrapper" v-for="(item,index) in summary" :key="index" @click="handleUpdateFilter(item)">
                <h5>{{index}}</h5>
                <span>{{item}}</span>
              </div> -->
            </div>
            <div class="d-flex justify-md-space-between align-center my-6">
                <div class="search-field d-flex align-center container-search">
                    <v-text-field
                        class="w-30"
                        v-model="query.search"
                        label="Search"
                        hide-details
                        clearable
                        @change="getRequests()"
                        dense
                        solo
                    ></v-text-field>
                    <v-btn
                        class="btn-search"
                        depressed
                        color="default"
                        elevation="2"
                        @click="getRequests()"
                        >
                        Search
                    </v-btn>
                </div>
                <div class="d-flex" style="gap:30px;width: 50%;">
                        <div class="d-flex flex-column flex-grow-1">
                            <label>Insurance Status</label>
                            <v-autocomplete
                            :items="insurance_status"
                            v-model="query.insurance_status"
                            text="text"
                            id="value"
                            label="Insurance Status"
                            solo
                            outlined
                            dense
                            clearable
                            @change="getRequests()"
                            ></v-autocomplete>
                        </div>
                        <div class="d-flex flex-column flex-grow-1">
                            <label>Payment Status</label>
                            <v-autocomplete
                              v-model="query.payment_status"
                              :items="payment_status"
                              text="text"
                              id="value"
                              label="Payment Status"
                              solo
                              outlined
                              dense
                              clearable
                              @change="getRequests()"
                            ></v-autocomplete>
                        </div>
                    </div>
                <div>
                  <v-btn
                      class="mr-4"
                      depressed
                      color="primary"
                      elevation="2"
                      @click="addApplication()"
                      >
                      <v-icon size="18">mdi-plus</v-icon>
                      Add
                  </v-btn>
                  <v-btn
                      depressed
                      color="primary"
                      elevation="2"
                      @click="export_modal = true"
                      >
                      <v-icon  size="18" >mdi-download</v-icon>
                      Export
                  </v-btn>
              </div>
            </div>
            <div>
              <v-data-table
                :headers="headers"
                :items="datas.data"
                :page.sync="page"
                :items-per-page="10"
                hide-default-footer
                calculate-widths
                class="elevation-1"
                @page-count="pageCount = $event"
              >
              <template v-slot:item.created_at="{ item }">
                  {{formattedDate(item.created_at)}}
              </template>

              <template v-slot:item.requestor="{ item }">
                  {{item.user.full_name}}
              </template>

              <template v-slot:item.company="{ item }">
                  {{item.sub_company.name}}
                  <!-- {{item.user.client_company ? item.user.client_company.company.name : "--"}} -->
              </template>
              <template v-slot:item.branch="{ item }">
                  {{item.user.client_company.branch ? item.user.client_company.branch.branch_name : "--"}}
              </template>

              <template v-slot:item.entity_id="{ item }">
                  {{ item.entity.name }}
              </template>

              <template v-slot:item.start_date="{ item }">
                  {{ $utils.changeDateFormat(item.start_date) }}
              </template>

              <template v-slot:item.in_charge="{ item }">
                  {{ item.in_charge.first_name }} {{ item.in_charge.last_name }}
              </template>
              
              <template v-slot:item.noa_amount="{ item }">
                  {{ formatNumber(item.noa_amount) }}
              </template>
              <template v-slot:item.insured_amount="{ item }">
                  {{ formatNumber(item.insured_amount) }}
              </template>
              <template v-slot:item.net_premium="{ item }">
                  {{ formatNumber(item.net_premium )}}
              </template>

              <template v-slot:item.insurance_status="{ item }">
                  {{ handleGetInsuranceStatus(item.insurance_status) }}
              </template>
              <template v-slot:item.payment_status="{ item }">
                  {{ handleGetPaymentStatus(item.payment_status) }}
              </template>

              <template v-slot:item.action="{ item }">
                <div class="d-flex">
                  <v-btn color="primary" icon small @click="goTo(item.id)">
                    <v-icon size="18">mdi-eye</v-icon>
                  </v-btn>
                  <template  v-if="item.user_id == user.id && item.insurance_status == 1">
                    |
                    <v-btn color="primary" icon small @click="handleDelete(item.id)">
                      <v-icon size="18" color="red">mdi-delete</v-icon>
                    </v-btn> 
                  </template>
                </div>
              </template>
                                                       
            
              </v-data-table>
              <div class="text-center pt-2">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                ></v-pagination>
              </div>
            </div>
        </v-card>
        <ExportModal :open_modal="export_modal" @handleExport="handleExportDate" @handleModalHide="export_modal = false"/>
    </div>
</template>

<script>
  import { changeFormat } from '@/utils/dateHelper';
  import { payment_status } from  '@/helpers/payment_status';
  import { insurance_status } from  '@/helpers/insurance_status';
  import ExportModal from  '../modals/Export.vue';

  export default {
    components:{
      ExportModal,
    },
    data () {
      return {
        // userStore : useUserStore().user,
        search:null,
        showeducationmodal:false,
        export_modal:false,
        showeligibilitymodal:false,
        page: 1,
        pageCount: 0,
        
        itemsPerPage: 10,
        user: this.$store.getters.user.user,
        headers: [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'DATE CREATED', value: 'created_at' },
          { text: 'START DATE', value: 'start_date' },
          { text: 'Validity', value: 'validity' },
          { text: 'Product Classification', value: 'product_classification' },
          { text: 'IN CHARGE', value: 'in_charge' },
          { text: 'COMPANY', value: 'company' },
          { text: 'BRANCH', value: 'branch' },
          { text: 'ENTITY', value: 'entity_id' },
          { text: 'NAME OF PROJECT', value: 'project_name' },
          { text: 'AMOUNT (NOA)', value: 'noa_amount' },
          { text: 'INSURED', value: 'insured_amount' },
          { text: 'NET PREMUIM', value: 'net_premium' },
          { text: 'INSURANCE STATUS', value: 'insurance_status' },
          { text: 'PAYMENT STATUS', value: 'payment_status' },
          { text: 'ACTION', align:'center', value: 'action' },
        ],
        datas:[],
        summary:null,
        payment_status:payment_status,
        insurance_status:insurance_status,
        query:{
          search : null,
          payment_status : null,
          insurance_status : null,
          in_home_page : true,
        }
      }
    },
    created(){
      if(!this.$store.getters.user.user.is_admin){
        if(this.$store.getters.user.user.default_user_company.role  == 'SA'){
          const header = this.headers.filter(header => {
              return header.value !== 'company'
            });
          return this.headers = header;
        }
        else{
          const header = this.headers.filter(header => {
              return header.value !== 'company' && header.value !== 'branch'
            });
          return this.headers = header;
        }
      }


    } , 

    mounted(){
      this.getRequests();
    },

    methods:{
      formatNumber(value) {
        // Format the number with commas
            if (typeof value !== 'string') {
                value = String(value || ''); // Ensure value is a string
            }
            const [integerPart, decimalPart] = value.split('.');
            const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;

       },
        filteredHeaders() {
          const header = this.headers.filter(header => {
            return header.value !== 'company'
          });
          this.headers = header;
        },
        handleExportDate(date_range) {
         this.handleExport(date_range)
        },
        handleFilterInsuranceStatus(value) {
         this.query.insurance_status = value
         this.getRequests()
        },
        handleFilterpaymentStatus(value) {
         this.query.payment_status = value
         this.getRequests()
        },
        handleFilter() {
          console.log(this.query)
        },
        handleDelete(id) {
          this.$notiflix.Confirm.show(
              'DELETE',
              'Do you want to delete this item?',
              'Yes',
              'No',
              () => {
                  this.handleDeleteRequest(id)
              },
          );
        },
        formattedDate(date) {
          return changeFormat(date); // Example format string
        },
        handleGetPaymentStatus(id) {
         const status_payment = payment_status.find(item=>item.value == id);
         return status_payment.text
        },
        handleGetInsuranceStatus(id) {
         const status = insurance_status.find(item=>item.value == id);
         return status.text
        },
        addApplication(){
            this.$router.push(`/application`)
        },
        goTo(id){
            this.$router.push(`/application/view/${id}`)
        },
        getRequests(){
           this.$notiflix.Loading.arrows();
           this.axios.get(`/request?user_company_id=${this.user.current_user_company_id}&query=${JSON.stringify(this.query)}`).then((res)=>{
                this.datas = res.data.data;
                this.summary = res.data.summary;
           }).catch((err)=>{
                console.log(err,'err')
           }).finally(()=>{
            this.$notiflix.Loading.remove();
           })
        },
        handleExport(date_range){
           this.$notiflix.Loading.arrows('Exporting...');
           this.axios.get(`/request/export?user_company_id=${this.user.current_user_company_id}&query=${JSON.stringify({...this.query, ...date_range})}`,{
              responseType: 'blob',
           }).then((response)=>{
              if(response.status == 200){
                      const url = window.URL.createObjectURL(new Blob([response.data]));
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute('download', 'request.csv'); 
                      document.body.appendChild(link);
                      link.click();
              }
           }).catch((err)=>{
                console.log(err,'err')
           }).finally(()=>{
            this.$notiflix.Loading.remove();
           })
        },
        handleDeleteRequest(id){
           this.$notiflix.Loading.arrows();
           this.axios.delete(`/request/${id}`).then((res)=>{
             this.$awn.success('Request Deleted Successfully')
             this.getRequests();
           }).catch((err)=>{
                console.log(err,'err')
           }).finally(()=>{
            this.$notiflix.Loading.remove();
           })
        },
    },
  }
</script>