import Vue from 'vue'
import VueRouter from 'vue-router'

// import main from '../components/pages/IndexPage.vue'
import main from '../App.vue'
import routes from './routes'
import login from '../components/pages/auth/login.vue';
import verify from '../components/pages/web/VerifyPage.vue';
import admin_login from '../components/pages/auth/admin_login.vue';

// import { getInfo } from '@/api/login'

Vue.use(VueRouter)

const routess = [
    ...routes,
]
const router = new VueRouter({
        base: '/bonds',
        mode: 'history',
        routes: [{
                path: '',
                name: 'main',
                redirect: '/home',
                component: main,
                // meta: {
                //     requiresAuth: true
                // },
                children: routess
            },
            {
                path: `/adminlogin`,
                name: `admin_login`,
                component: admin_login,
            },
            {
                path: '/login',
                name: 'login',
                component: login,
                // meta: {
                //     requiresAuth: false
                // },
            },
            {
                path: '/web/verify',
                name: 'verify',
                component: verify,
            }
        ]
    })


// router.beforeEach((to, from, next) => {
//     const requiresAuth = to.matched.some((route) => route.meta.requiresAuth)
//     const isAuthenticated = Cookies.get('token')
//     if (!requiresAuth && isAuthenticated) {
//         next(from);
//     } else if (requiresAuth && !isAuthenticated) {
//         next('/login');
//     } else {
//         next();
//     }
// });

export default router